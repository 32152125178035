<template>
  <DataTable
    class="p-datatable-sm"
    ref="dt"
    :value="student_list"
    dataKey="id"
    :filters="filter.filter_list"
    filterDisplay="menu"
    :loading="loading"
    responsiveLayout="stack"
    breakpoint="960px"
    :paginator="true"
    :rows="6"
    :resizableColumns="true"
    columnResizeMode="fit"
    :rowsPerPageOptions="[6, 10, 20, 50]"
    paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
    currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
  >
    <template #header>
      <!-- 
            <div class="card-header flex  p-0 pt-0 border-0">
                    <h3 class="card-title w-100 u-flex">
                        <span class="card-label fw-bolder fs-3 mb-1 u-flex-1"  v-if="class_id == 0 && teacher_id == 0">Student List</span>
                        <div class="p-d-flex p-jc-between me-2 searchbar-width">
                            <span class="p-input-icon-left text-blue">
                                <i class="pi pi-search" />
                                
                            </span>
                        </div>
                        <a href="Javascript:void(0)" @click="getStudentList(class_id, teacher_id)" class="ml-2 btn btn-sm btn-light-primary fw-600 me-2">
                            <i class="pi pi-search  search-icon" />
                        </a>
                        
                    </h3>
                    <div class="card-actions" style="width: 234px" v-if="class_id != 0 && teacher_id != 0">
                        <a href="Javascript:void(0)" v-if="user.user_type == 'teacher'" @click="addStudent" class="float-right btn font-weight-bolder add-action ">
                            <i class="fas fa-plus-circle icon-md"></i>Add Student
                        </a>
                    </div>
            </div>
            -->
    </template>
    <Column field="fname" header="Firstname" :sortable="true">
      <template #body="slotProps">
        <a href="Javascript:void(0)" @click="info(slotProps.data)" class="d-flex">
          <Avatar
            v-if="
              slotProps.data.thumbnail_type == 'image' ||
              slotProps.data.thumbnail_type == 'upload' ||
              slotProps.data.thumbnail_type == 'library'
            "
            :image="`${slotProps.data.path}${slotProps.data.thumbnail}`"
            class="p-mr-2"
            size="xlarge"
            shape="circle"
          />
          <div
            v-if="slotProps.data.thumbnail_type == 'color'"
            :style="{
              'background-color': `#${slotProps.data.thumbnail_bg}`,
              height: '40px',
              width: '40px',
              'border-radius': '50%',
            }"
          ></div>
          <p class="ml-5 pt-4">{{ slotProps.data.fname }}</p>
        </a>
      </template>
    </Column>
    <Column field="lname" header="Lastname" :sortable="true"></Column>
    <Column field="email" header="Email" :sortable="true"></Column>
    <Column field="gender" header="Gender" :sortable="true">
      <template #body="slotProps">
        <span
          v-if="slotProps.data.gender == 'Male'"
          class="label label-lg font-weight-bold label-light-primary label-inline"
          >{{ slotProps.data.gender }}</span
        >
        <span
          v-if="slotProps.data.gender == 'Female'"
          class="label label-lg font-weight-bold label-light-info label-inline"
          >{{ slotProps.data.gender }}</span
        >
        <span
          v-if="slotProps.data.gender == 'Non-binary'"
          class="label label-lg font-weight-bold label-light-info label-inline"
          >{{ slotProps.data.gender }}</span
        >
      </template>
    </Column>
    <Column field="class_id" header="Class" :sortable="true">
      <template #body="slotProps">
        <span v-if="slotProps.data.classes.length > 0">
          <span
            v-for="(row, t) in slotProps.data.classes"
            :key="t"
            class="label label-lg font-weight-bold mb-2 label-light-success label-inline mr-1 ml-1"
          >
            <span v-if="row.classname.length">{{ row.classname[0] }}</span>
          </span>
        </span>
      </template>
    </Column>
    <Column field="created_at" header="Created At" :sortable="true">
      <template #body="props">
        <p class="text-muted pt-2">{{ moment(props.data.created_at).format("LL") }}</p>
      </template>
    </Column>
    <Column field="id" header="">
      <template #body="slotProps">
        <a
          href="javascript:void(0);"
          @click="studentSurvey(slotProps.data)"
          v-tooltip.top="'Start Survey'"
          class="mr-2"
          data-toggle="dropdown"
        >
          <span class="svg-icon svg-icon-primary svg-icon-md">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              xmlns:xlink="http://www.w3.org/1999/xlink"
              width="24px"
              height="24px"
              viewBox="0 0 24 24"
              version="1.1"
              class="svg-icon"
            >
              <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                <rect x="0" y="0" width="24" height="24"></rect>
                <path
                  d="M7,3 L17,3 C19.209139,3 21,4.790861 21,7 C21,9.209139 19.209139,11 17,11 L7,11 C4.790861,11 3,9.209139 3,7 C3,4.790861 4.790861,3 7,3 Z M7,9 C8.1045695,9 9,8.1045695 9,7 C9,5.8954305 8.1045695,5 7,5 C5.8954305,5 5,5.8954305 5,7 C5,8.1045695 5.8954305,9 7,9 Z"
                  fill="#000000"
                ></path>
                <path
                  d="M7,13 L17,13 C19.209139,13 21,14.790861 21,17 C21,19.209139 19.209139,21 17,21 L7,21 C4.790861,21 3,19.209139 3,17 C3,14.790861 4.790861,13 7,13 Z M17,19 C18.1045695,19 19,18.1045695 19,17 C19,15.8954305 18.1045695,15 17,15 C15.8954305,15 15,15.8954305 15,17 C15,18.1045695 15.8954305,19 17,19 Z"
                  fill="#000000"
                  opacity="0.3"
                ></path>
              </g>
            </svg>
          </span>
        </a>
        <el-dropdown @command="handleCommand">
          <span class="el-dropdown-link">
            <a href="javascript:void(0);" class="mr-2" data-toggle="dropdown">
              <span class="svg-icon svg-icon-primary svg-icon-md">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  xmlns:xlink="http://www.w3.org/1999/xlink"
                  width="24px"
                  height="24px"
                  viewBox="0 0 24 24"
                  version="1.1"
                >
                  <g stroke="none" stroke-width="1">
                    <rect x="14" y="9" width="6" height="6" rx="3" fill="black" />
                    <rect
                      x="3"
                      y="9"
                      width="6"
                      height="6"
                      rx="3"
                      fill="black"
                      fill-opacity="0.7"
                    />
                  </g>
                </svg>
              </span>
            </a>
          </span>
          <template #dropdown>
            <el-dropdown-menu>
              <el-dropdown-item :command="{ action: 'edit', data: slotProps.data }"
                ><i class="pi pi-pencil text-primary"></i> Edit Students
              </el-dropdown-item>
              <el-dropdown-item :command="{ action: 'delete', data: slotProps.data }"
                ><i class="pi pi-trash text-primary"></i> Delete Student</el-dropdown-item
              >
              <el-divider></el-divider>
              <el-dropdown-item :command="{ action: 'info', data: slotProps.data }"
                ><i class="pi pi-user text-primary"></i> View Info
              </el-dropdown-item>
              <el-dropdown-item :command="{ action: 'notes', data: slotProps.data }"
                ><i class="pi pi-tag text-primary"></i> Add Notes
              </el-dropdown-item>

              <el-dropdown-item
                v-if="user.user_type == 'teacher'"
                :command="{ action: 'survey', data: slotProps.data }"
              >
                <span class="svg-icon svg-icon-primary svg-icon-md mr-2">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    xmlns:xlink="http://www.w3.org/1999/xlink"
                    width="24px"
                    height="24px"
                    viewBox="0 0 24 24"
                    version="1.1"
                    class="svg-icon"
                  >
                    <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                      <rect x="0" y="0" width="24" height="24"></rect>
                      <path
                        d="M7,3 L17,3 C19.209139,3 21,4.790861 21,7 C21,9.209139 19.209139,11 17,11 L7,11 C4.790861,11 3,9.209139 3,7 C3,4.790861 4.790861,3 7,3 Z M7,9 C8.1045695,9 9,8.1045695 9,7 C9,5.8954305 8.1045695,5 7,5 C5.8954305,5 5,5.8954305 5,7 C5,8.1045695 5.8954305,9 7,9 Z"
                        fill="#000000"
                      ></path>
                      <path
                        d="M7,13 L17,13 C19.209139,13 21,14.790861 21,17 C21,19.209139 19.209139,21 17,21 L7,21 C4.790861,21 3,19.209139 3,17 C3,14.790861 4.790861,13 7,13 Z M17,19 C18.1045695,19 19,18.1045695 19,17 C19,15.8954305 18.1045695,15 17,15 C15.8954305,15 15,15.8954305 15,17 C15,18.1045695 15.8954305,19 17,19 Z"
                        fill="#000000"
                        opacity="0.3"
                      ></path>
                    </g>
                  </svg>
                </span>
                Start Survey
              </el-dropdown-item>
            </el-dropdown-menu>
          </template>
        </el-dropdown>
      </template>
    </Column>
    <template #loading> Loading students data. Please wait. </template>
    <template #empty> No students found. </template>
  </DataTable>
</template>
<script>
import { defineComponent, inject, computed } from "vue";
import { ElDropdown, ElDropdownItem, ElDropdownMenu, ElDivider } from "element-plus";
import DataTable from "primevue/datatable";
import Column from "primevue/column";
import Avatar from "primevue/avatar";
import moment from "moment";

export default defineComponent({
  components: {
    Avatar,
    ElDropdown,
    ElDropdownItem,
    ElDropdownMenu,
    DataTable,
    Column,
    ElDivider,
  },
  props: {
    student_list: {
      default: [],
    },
    loading: {
      default: false,
      type: Boolean,
    },
    filter: {
      default: [],
    },
  },
  setup() {
    const { constants, store } = inject("App");
    const { handleCommand, info, studentSurvey } = inject("Student");

    return {
      handleCommand,
      info,
      studentSurvey,
      moment,
      user: computed(() => store.getters["UserModule/GET_USER"]),
    };
  },
});
</script>
