import { ref } from 'vue'
export default function TermsAdd(http, constant, toast) {
    let term_add_loading = ref(false)
    
    async function addTerm(class_id, term, week_no) {
        term_add_loading.value = true
        try {
            const f = new URLSearchParams()
            f.append('class_id', class_id)
            f.append('term', term)
            f.append('week_no', week_no)
            const response = await http.post(constant.TERM_ADD, f)

            if (response.data.status == 0) {
                toast.add({severity:'error', summary: 'Ooops!', detail: response.data.message , life: 3000});
            }
            else
                term_add_loading.value = false
        } catch (err) {
            term_add_loading.value = false
        }
    }
    return {
        term_add_loading, addTerm
    }
}