<template>
    <div class="card card-custom gutter-b card-stretch">
        <div class="card-body pt-4">
            <div class="d-flex justify-content-end">
                <div class="dropdown dropdown-inline show" data-toggle="tooltip" title="" data-placement="left" data-original-title="Quick actions">
                    <el-dropdown @command="handleCommand">
                        <span class="el-dropdown-link">
                            <span class="svg-icon svg-icon-primary svg-icon-md">
                                <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                    <g stroke="none" stroke-width="1">
                                        <rect x="14" y="9" width="6" height="6" rx="3" fill="black" />
                                        <rect x="3" y="9" width="6" height="6" rx="3" fill="black" fill-opacity="0.7" />
                                    </g>
                                </svg>
                            </span>
                        </span>
                        <template #dropdown>
                            <el-dropdown-menu>
                                <el-dropdown-item v-if="user.user_type == 'school'" :command="{ action: 'edit', data: item }"><i class="pi pi-pencil text-primary"></i> Edit Class </el-dropdown-item>
                                <el-dropdown-item :command="{ action: 'view-students', data: item }"><i class="pi pi-users text-primary"></i> View Students</el-dropdown-item>
                                <el-dropdown-item :command="{ action: 'report', data: item }"><i class="pi pi-chart-bar text-primary"></i> View Reports</el-dropdown-item>
                                <el-dropdown-item v-if="user.user_type == 'school'" :command="{ action: 'delete', data: item }"><i class="pi pi-trash text-primary"></i> Delete Class</el-dropdown-item>
                                <el-divider v-if="user.user_type == 'teacher'"></el-divider>
                                <el-dropdown-item :command="{ action: 'survey', data: item }">
                                    <span class="svg-icon svg-icon-primary svg-icon-md mr-2">
                                        <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1" class="svg-icon">
                                            <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                                <rect x="0" y="0" width="24" height="24"></rect>
                                                <path d="M7,3 L17,3 C19.209139,3 21,4.790861 21,7 C21,9.209139 19.209139,11 17,11 L7,11 C4.790861,11 3,9.209139 3,7 C3,4.790861 4.790861,3 7,3 Z M7,9 C8.1045695,9 9,8.1045695 9,7 C9,5.8954305 8.1045695,5 7,5 C5.8954305,5 5,5.8954305 5,7 C5,8.1045695 5.8954305,9 7,9 Z" fill="#000000"></path>
                                                <path d="M7,13 L17,13 C19.209139,13 21,14.790861 21,17 C21,19.209139 19.209139,21 17,21 L7,21 C4.790861,21 3,19.209139 3,17 C3,14.790861 4.790861,13 7,13 Z M17,19 C18.1045695,19 19,18.1045695 19,17 C19,15.8954305 18.1045695,15 17,15 C15.8954305,15 15,15.8954305 15,17 C15,18.1045695 15.8954305,19 17,19 Z" fill="#000000" opacity="0.3"></path>
                                            </g>
                                        </svg>
                                    </span>
                                    Start Survey
                                </el-dropdown-item>
                            </el-dropdown-menu>
                        </template>
                    </el-dropdown>
                </div>
            </div>
            <div class="d-flex align-items-center mb-7 mt-0">
                <div class="flex-shrink-0 mr-4">
                    <div class="symbol symbol-circle symbol-lg-75">
                        <img v-if="item.thumbnail_type == 'image' || item.thumbnail_type == 'upload' || item.thumbnail_type == 'library'" :src="`${web_url}${item.thumbnail}`" alt="image" />
                        <div v-if="item.thumbnail_type == 'color'" :style="{ 'background-color': `#${item.thumbnail_bg}`, height: '50px', width: '50px', 'border-radius': '50%' }"></div>
                    </div>
                </div>
                <div class="d-flex flex-column">
                    <a href="#" class="text-dark font-weight-bold text-hover-primary font-size-h4 mb-0">{{ item.name }}</a>
                </div>
            </div>
            <div class="mb-7">
                <div class="d-flex justify-content-between align-items-center">
                    <span class="text-dark-75 font-weight-bolder mr-2"># of students</span>
                    <a href="Javascript:void(0)" @click="handleCommand({ action: 'view-students', data: item })" class="text-primary text-hover-primary">{{ item.studentcount }}</a>
                </div>
            </div>
            <div class="mb-0" v-if="user.user_type == 'school'">
                <p class="text-dark-75 font-weight-bolder mr-2">Teachers</p>
                <span v-if="item.teachers.length > 0">
                    <span v-for="(teacher, t) in item.teachers" :key="t" class="label label-lg font-weight-bold mb-2 label-light-primary label-inline mr-1 ml-1">{{ teacher.name }}</span>
                </span>
            </div>
        </div>
        <div class="card-footer d-flex align-items-sm-center flex-wrap flex-column flex-sm-row justify-content-between" style="padding: 1rem 2.25rem">
            <div :class="{'pt-4': user.user_type == 'teacher'}" class="d-flex w-100" style="justify-content: center;">
                <p class="text-muted">{{ moment(item.created_at).format("LL") }}</p>
            </div>
            <div class="d-flex">
                <button type="button" v-if="user.user_type == 'school'" @click="handleCommand({ action: 'view-students', data: item })" class="btn btn-light btn-sm mt-sm-0 mr-auto mr-sm-0 ml-sm-4">View Students</button>
                <button type="button" @click="handleCommand({ action: 'report', data: item })" class="btn btn-light btn-sm mt-sm-0 mr-auto mr-sm-0 ml-sm-4">Reports</button>
                <button type="button" @click="studentSurvey(item)" class="btn btn-light btn-sm mt-sm-0 mr-auto mr-sm-0 ml-sm-4">Start Survey</button>
            </div>
        </div>
    </div>
</template>
<script>
import { defineComponent, inject, computed } from "vue";
import { ElDropdown, ElDropdownItem, ElDropdownMenu, ElDivider } from "element-plus";
import moment from "moment";
export default defineComponent({
    components: { ElDropdown, ElDropdownItem, ElDropdownMenu, ElDivider },
    props: {
        item: {
            default: [],
        },
        index: {
            type: Number,
            default: 0,
        },
    },
    setup() {
        const { store, constants } = inject("App");
        const { handleCommand, studentSurvey } = inject("Class");
        return {
            handleCommand,
            studentSurvey,
            moment,
            user: computed(() => store.getters["UserModule/GET_USER"]),
            web_url: `${process.env.VUE_APP_AWS_URL}`,
        };
    },
});
</script>
