import { ref } from 'vue'
export default function StudentsGetList(http, constant, store) {
    let student_loading = ref(false)
    let student_list = ref([])

    async function getStudentList(class_id, teacher_id) {
        student_loading.value = true;
        try {
            const f = new URLSearchParams()
            f.append('class_id', class_id)
            f.append('teacher_id', teacher_id)


            const response = await http.post(constant.STUDENT_LIST, f)
            student_loading.value = false
            student_list.value = response.data.data
        } catch (err) {
            student_loading.value = false;
        }
    }
    return {
        student_loading, student_list, getStudentList
    }
}