 <template>
    <Sidebar  v-model:visible="display" position="right" class="p-sidebar-md"  :dismissable="false" :modal="true" :showCloseIcon="false" >
    <form  ref="studentData" @submit.prevent="importFile"  action="post"  novalidate >
            <div class="rpanel-title text-capitalize">
                <div class="flex">
                    <div class="w-100">
                        <p>Students</p>
                        <small>Import Student</small>
                    </div>
                    <span @click="display = false"  class="cursor-pointer fs-20"><i class="pi pi-times"></i></span>
                </div>
            </div>
            <div class="drawer-content">
                <div  class="p-10   pt-0 mt-0">
                    <div class="alert alert-custom alert-white alert-shadow fade show gutter-b" role="alert">
                        <div class="alert-icon">
                            <span class="svg-icon svg-icon-primary svg-icon-xl">
                                <!--begin::Svg Icon | path:/metronic/theme/html/demo1/dist/assets/media/svg/icons/Tools/Compass.svg-->
                                <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                    <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                        <rect x="0" y="0" width="24" height="24"></rect>
                                        <path d="M7.07744993,12.3040451 C7.72444571,13.0716094 8.54044565,13.6920474 9.46808594,14.1079953 L5,23 L4.5,18 L7.07744993,12.3040451 Z M14.5865511,14.2597864 C15.5319561,13.9019016 16.375416,13.3366121 17.0614026,12.6194459 L19.5,18 L19,23 L14.5865511,14.2597864 Z M12,3.55271368e-14 C12.8284271,3.53749572e-14 13.5,0.671572875 13.5,1.5 L13.5,4 L10.5,4 L10.5,1.5 C10.5,0.671572875 11.1715729,3.56793164e-14 12,3.55271368e-14 Z" fill="#000000" opacity="0.3"></path>
                                        <path d="M12,10 C13.1045695,10 14,9.1045695 14,8 C14,6.8954305 13.1045695,6 12,6 C10.8954305,6 10,6.8954305 10,8 C10,9.1045695 10.8954305,10 12,10 Z M12,13 C9.23857625,13 7,10.7614237 7,8 C7,5.23857625 9.23857625,3 12,3 C14.7614237,3 17,5.23857625 17,8 C17,10.7614237 14.7614237,13 12,13 Z" fill="#000000" fill-rule="nonzero"></path>
                                    </g>
                                </svg>
                                <!--end::Svg Icon-->
                            </span>
                        </div>
                        <div class="alert-text">You can mass import all of your existing students at once by following the steps below…</div>
                    </div>
                    <div class="table-responsive m-b-30">
                        <table class="table table-borderless">
                            <tbody>
                                <tr>
                                    <td style="width: 100px;">Step 1)</td>
                                    <td><a :href="`${env.VUE_APP_API_SITE}student-csv-format.csv`" download>Download the sample CSV file here</a></td>
                                </tr>
                                <tr>
                                    <td>Step 2)</td>
                                    <td>
                                        Open the CSV with a spreadsheet application (such as
                                        Excel), then fill out your client information.
                                    </td>
                                </tr>
                                <tr>
                                    <td style="width: 100px;">Step 3)</td>
                                    <td>
                                        <div class="form-group">
                                                <label>Select Class <span class="text-danger">*</span></label>
                                                <select class="form-control" name="class"  v-model="selected_class">
                                                    <option value="">Select</option>
                                                    <option v-for="(item, i ) in class_list" :key="i" :value="item.id">{{item.name}}</option>
                                                </select>
                                            </div>
                                            <div class="form-group" v-if="selected_class != ''">
                                                <el-upload
                                                        class="upload-demo"
                                                        drag
                                                        :action="post_url"
                                                        name="file"
                                                        :on-success="success"
                                                    >
                                                        <el-icon class="el-icon--upload"><upload-filled /></el-icon>
                                                        <div class="el-upload__text">
                                                        Drop file here or <em>click to upload</em>
                                                        </div>
                                                        <div class="el-upload__tip">
                                                            upload a csv, excel file
                                                        </div>
                                                    </el-upload>
                                            </div>
                                    </td>
                                    <td></td>
                                </tr>
                            </tbody>
                        </table>
                    </div>


                    
                </div>
            </div>
            <div class="r-panel-footer drawer-footer--v2">
                <div class="r-panel-footer-content">
                    <button type="button" :disabled="import_status" @click="display = false" class="cursor-pointer btn btn-sm btn-secondary om-add-contact-toggle mr-2">Cancel</button>
                    <button type="submit" :disabled="import_status" class="btn btn-sm btn-info save mr-2">
                        <span  v-if="!import_status">  Import</span>
                        <span v-else><i class="fas fa-spinner fa-spinner"></i> Importing...</span>
                    </button>
                </div>
            </div>
    </form>
    </Sidebar>
</template>
<script>
import { defineComponent, ref, watch, computed, inject } from 'vue'
import Sidebar from 'primevue/sidebar';
import { ElUpload, ElIcon }   from  'element-plus'
import { UploadFilled } from '@element-plus/icons-vue'
import  StudentsImport  from '@/composables/students/StudentsImport'
 
export default defineComponent({
    components: { Sidebar, ElIcon, ElUpload, UploadFilled },
    props: {
        student_import_watcher: {
            type: Number,
            default: 1
        },
         class_list: {
            default: []
        },
        class_id: {
            type: Number,
            default: 0
        },
        teacher_id: {
            type: Number,
            default: 0
        }
    },
    setup(props) {
        const { http, constants, toast,store } = inject('App')
        const { import_status, importStudents } = StudentsImport(http, constants, toast)
        const display = ref(false)
        const selected_class = ref('')
        const filename = ref('')
        let teacher = computed(() => store.getters['UserModule/GET_TEACHER'])
        const { getStudentList } = inject('Student')

        
        function importFile() {
                importStudents(selected_class.value, teacher.value.id, filename.value )
        }
        function success(response, file, file_list) {
            console.log(response.file)
            filename.value = response.file
        }
        watch(import_status, (n, o) => {
            if (import_status.value == false) {
                getStudentList(props.class_id, props.teacher_id)
                display.value = false
            }
        });
        
        watch(()=>props.student_import_watcher, (n) => {
            display.value = true
            selected_class.value = ''
         })

         return {
             display, selected_class, import_status, importFile, success,
             teacher, filename,
             post_url: `${process.env.VUE_APP_API_URL}students/upload-csv?token=${localStorage.getItem("token")}`,
             env: process.env,
             constants
         }
    },
})
</script>
