<template>
    <div>
    <Sidebar  v-model:visible="display" position="right" class="p-sidebar-md"  :dismissable="false" :modal="true" :showCloseIcon="false" >
        <div class="rpanel-title text-capitalize">
            <div class="flex">
                <div class="w-100">
                    <p>Classes</p>
                    <small v-if="type == 'add'">Add Class</small>
                    <small v-else>Edit Class</small>
                </div>
                <span @click="display = false"  class="cursor-pointer fs-20"><i class="pi pi-times"></i></span>
            </div>
        </div>
        <div class="drawer-content">
            <div  class="p-10   pt-0 mt-0">
                <div class="form-group">
                    <label>Select Teacher <span class="text-danger">*</span></label>
                    <MultiSelect v-model="selected_teacher" :options="activeTeacherList" optionLabel="name" placeholder="Select teachers"
                        :filter="true" class="multiselect-custom">
                        <template #value="slotProps">
                            <div class="country-item country-item-value" v-for="option of slotProps.value" :key="option.name">
                                <img :src="`${option.user.path}${option.user.thumbnail}`" class="p-mr-2" width="18" />
                                <div  class="pl-3 pr-3 text-capitalize">{{option.name}}</div>
                            </div>
                            <template v-if="!slotProps.value || slotProps.value.length === 0">
                                Select teacher
                            </template>
                        </template>
                        <template #option="slotProps">
                            <div class="country-item d-flex">
                                <img :src="`${slotProps.option.user.path}${slotProps.option.user.thumbnail}`" class="p-mr-2 mr-2" width="18" />
                                <div class="text-capitalize" >{{slotProps.option.name}}</div>
                            </div>
                        </template>
                    </MultiSelect>
                </div>

                <div class="form-group">
                    <label>Class <span class="text-danger">*</span></label>
                    <input type="text" class="form-control" name="lname" v-model="name" placeholder="Enter class name" required/>
                </div>
                <div class="form-group">    
                    <label class="w-100">Thumbnail type</label>
                    <div class="row mb-5 pb-5 hide" >
                        <div class="col-sm-12 col-md-12">
                            <RadioButton id="multi" :value="1" v-model="thumbnail_src" />
                            <label for="multi" class="checkbox-label">(Automatic) Generate from random avatars</label>
                        </div>
                        <div class="col-sm-12 col-md-12 pt-4">
                            <RadioButton id="long"  :value="2" v-model="thumbnail_src" />
                            <label for="long" class="checkbox-label">Select</label>
                        </div>
                    </div>
                    <div v-if="thumbnail_src == 2">
                        <el-radio-group v-model="thumbnail_type" style="margin-bottom: 30px">
                            <el-radio-button label="color">Color</el-radio-button>
                            <el-radio-button label="upload"  >Upload</el-radio-button>
                            <el-radio-button label="image"  >Free Images</el-radio-button>
                            <el-radio-button label="library"  >Image Library</el-radio-button>
                        </el-radio-group>
                        <div class="row pt-0 mt-0" v-if="thumbnail_type == 'color'">
                            <div class="col-12 pt-0 mt-0">
                                <div class="box-shadowv3 ">
                                      <el-color-picker v-model="thumbnail_color" />
                                </div>
                            </div>
                        </div>
                        <div class="row pt-0 mt-0" v-if="thumbnail_type == 'upload'">
                           <div class="col-12 pt-0 mt-0">
                                <div class="thumb_preview cursor-pointer" >
                                    <img v-if="upload_thumb != ''"  :src="`${web_url}${upload_thumb}`" style="width: 200px; height: 198px">
                                    <i  v-if="upload_thumb == ''"   class="pi pi-cloud-upload icon-download"></i>
                                    <i @click="resetUploadThumbPreview"  v-if="upload_thumb != ''" class="pi pi-times icon-close"></i>
                                </div>
                                <div class="upload-example__buttons-wrapper flex">
                                    <button  type="button" class="upload-example__button  mt-3" @click="$refs.file.click()">
                                        <input ref="file" type="file" accept="image/*" @change="loadImage($event)" />Select Image
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div class="row pt-0 mt-0" v-if="thumbnail_type == 'library'">
                            <div class="col-12 pt-0 mt-0">
                                <div class="thumb_preview cursor-pointer">
                                    <img v-if="library_thumb != ''"  @click="showLibrary" :src="`${web_url}${library_thumb}`" style="width: 200px; height: 198px">
                                    <i  v-if="library_thumb == ''"  @click="showLibrary" class="pi pi-images icon-download"></i>
                                    <i @click="resetLibraryThumbPreview"  v-if="library_thumb != ''" class="pi pi-times icon-close"></i>
                                </div>
                                <span>Recommended size is 120x120</span>
                            </div>
                        </div>
                        <div class="row pt-0 mt-0" v-if="thumbnail_type == 'image'">
                            <div class="col-12 pt-0 mt-0">
                                <div class="thumb_preview cursor-pointer">
                                    <img v-if="class_thumb != ''"  @click="uploadEvent" :src="`${web_url}${class_thumb}`" style="width: 200px; height: 198px">
                                    <i  v-if="class_thumb == ''"  @click="uploadEvent" class="pi pi-cloud-upload icon-download"></i>
                                    <i @click="resetPreview"  v-if="class_thumb != ''" class="pi pi-times icon-close"></i>
                                </div>
                                <span>Recommended size is 120x120</span>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
        <div class="r-panel-footer drawer-footer--v2">
                <div class="r-panel-footer-content">
                    <button type="button" :disabled="create_status" @click="display = false" class="cursor-pointer btn btn-sm btn-secondary om-add-contact-toggle mr-2">Cancel</button>
                    <button type="submit" :disabled="create_status"   @click="create"   class="btn btn-sm btn-info save mr-2">
                        <span  v-if="!create_status">  Save</span>
                        <span v-else><i class="fas fa-spinner fa-spinner"></i> Saving...</span>
                    </button>
                </div>
        </div>
    </Sidebar>
    <PexelsImages :modules="`ClassModule/SET_CLASS_THUMB`" :pexels_image_watcher="pexels_image_watcher"  :source='`class_thumb`'></PexelsImages>
    <ImageLibrary :path="`thumbnail/class`" :modules="`ClassModule/SET_CLASS_LIBRARY_THUMB`"   :thumbnail_type="`thumbnail`" :image_library_watcher="image_library_watcher" :type="`class_library_thumb`"></ImageLibrary>
    <ImageCropper :modules="`ClassModule/SET_CLASS_UPLOAD_THUMB`" :ratioH="120" :ratioW="120" :selected_image="upload_thumb_set.src" :image_type="`blob`" :source="`class_upload_thumb`" :image_cropper_watcher="image_cropper_watcher"></ImageCropper>
    </div>
</template>
<script>

import  ClassCreate  from '@/composables/class/ClassCreate'


import { defineComponent, ref, watch,  inject, computed, reactive, provide } from 'vue'  
import Sidebar from 'primevue/sidebar';
import MultiSelect from 'primevue/multiselect';
import { ElRadioGroup, ElRadioButton, ElColorPicker }   from  'element-plus'
import ColorPicker from 'primevue/colorpicker';
import PexelsImages from '@/components/PexelsImages.vue'
import RadioButton from 'primevue/radiobutton';
import ImageCropper from '@/components/ImageCropper.vue';
import ImageLibrary from '@/components/ImageLibrary.vue';

export default defineComponent({
    props: {
        add_class_watcher: {
            tpe: Number,
            default: 1
        },
        selected_class: {
            default: []
        },
        teacher_list: {
            default: []
        },
        type: {
            default: 'create',
            type: String
        },
    },
    components: { ImageCropper, ImageLibrary, Sidebar, MultiSelect, ElRadioGroup, ElRadioButton, RadioButton, ElColorPicker, PexelsImages },
    setup(props) {
        const { utils, store, toast, http, constants } = inject('App') 

        let display = ref(false)
        let name = ref(false)
        let selected_teacher = ref('')
        const { create_status, createClass } = ClassCreate(http, constants, toast)
        const { getClassList } = inject('Class')
        const thumbnail_type = ref('color')
        const pexels_image_watcher = ref(1)
        const thumbnail_color = ref('#893BF7')
        const thumbnail_src = ref(2)
        const activeTeacherList = ref([])
        
        let upload_thumb_set = reactive({
				src: null,
				type: null
        })
        const image_cropper_watcher = ref(1)
        const image_library_watcher = ref(1)

        let class_thumb =  computed(() => store.getters['ClassModule/GET_CLASS_THUMB'])
        let upload_thumb =  computed(() => store.getters['ClassModule/GET_CLASS_UPLOAD_THUMB'])
        let library_thumb =  computed(() => store.getters['ClassModule/GET_CLASS_LIBRARY_THUMB'])


        function create() {
            let image  = ''
            if (thumbnail_type.value == 'upload')
                image = upload_thumb.value
            else if (thumbnail_type.value == 'library')
                image = library_thumb.value
            else
                image = class_thumb.value

            createClass(props.selected_class.id, props.type, name.value, selected_teacher.value,
                        thumbnail_type.value, removeHex(thumbnail_color.value), image)
        }   
         function removeHex(str) {
            return str = str.substring(1);
        }
        function uploadEvent() {
          pexels_image_watcher.value++
        }
        function resetPreview() {
            if (props.type == 'add')
              store.commit('ClassModule/SET_CLASS_THUMB', '')
            else 
              store.commit('ClassModule/SET_CLASS_THUMB', props.selected_class.thumbnail)
        }
        function resetUploadThumbPreview() {
            if (props.type == 'add')
              store.commit('ClassModule/SET_CLASS_UPLOAD_THUMB', '')
            else 
              store.commit('ClassModule/SET_CLASS_UPLOAD_THUMB', props.selected_class.thumbnail)
        }
        function resetLibraryThumbPreview() {
            if (props.type == 'add')
              store.commit('ClassModule/SET_CLASS_LIBRARY_THUMB', '')
            else 
              store.commit('ClassModule/SET_CLASS_LIBRARY_THUMB', props.selected_class.thumbnail)
        }
        function loadImage(event) {
			const { files } = event.target;
			if (files && files[0]) {
				if (upload_thumb_set.src) {
					URL.revokeObjectURL(upload_thumb_set.src)
				}
				const blob = URL.createObjectURL(files[0]);
				const reader = new FileReader();
				reader.onload = (e) => {
                    upload_thumb_set.src = blob
                    upload_thumb_set.type =  utils.getMimeType(e.target.result, files[0].type)
                    image_cropper_watcher.value++
                    
				};
				reader.readAsArrayBuffer(files[0]);
			}
		}
        function showLibrary() {
            image_library_watcher.value++
        }
        function closeDialog(){}
        function getSelectedTeacher() {
            let arr = []
            props.selected_class.teachers.forEach(element => {
                arr.push({
                    id: element.id,
                    name: element.name,
                    user: element.user
                })
            });
            selected_teacher.value  = arr
        }
        function getTeacherList() {
            let arr = []
            props.teacher_list.forEach(element => {
                arr.push({
                    id: element.id,
                    name: element.name,
                    user: element.user
                })
            });
            activeTeacherList.value  = arr
        }
        watch(create_status, (n, o) => {
            if (create_status.value == false) {
                getClassList()
                display.value = false
            }
        });
        watch(()=>props.add_class_watcher, (n) => {
            display.value = true

            name.value = ''
            getTeacherList()
            if (props.type == 'edit') {
                name.value = props.selected_class.name
                getSelectedTeacher()

                //selected_teacher.value = props.selected_class.teachers

                store.commit('ClassModule/SET_CLASS_THUMB', props.selected_class)
                store.commit('ClassModule/SET_CLASS_UPLOAD_THUMB', props.selected_class)
                store.commit('ClassModule/SET_CLASS_LIBRARY_THUMB', props.selected_class)

                thumbnail_type.value = props.selected_class.thumbnail_type
                thumbnail_color.value = `#${props.selected_class.thumbnail_bg}`
            } else {
                store.commit('ClassModule/SET_CLASS_THUMB', '')
                store.commit('ClassModule/SET_CLASS_UPLOAD_THUMB', '')
                store.commit('ClassModule/SET_CLASS_LIBRARY_THUMB', '')

                selected_teacher.value  = ''
                thumbnail_type.value = 'color'
                thumbnail_color.value = '#893BF7'
            }
        })
        provide('CropperParent', { closeDialog })

        return {
            display, name, create_status,  create, selected_teacher,
            web_url: `${process.env.VUE_APP_AWS_URL}`,
            web_user_url: `${process.env.VUE_APP_API_SITE}${constants.USERS_THUMBNAIL_PATH}`,
            thumbnail_type, pexels_image_watcher, uploadEvent, class_thumb, resetPreview,
            thumbnail_color, thumbnail_src,
            upload_thumb_set, upload_thumb, library_thumb, resetUploadThumbPreview, resetLibraryThumbPreview,
            showLibrary, image_cropper_watcher, image_library_watcher, loadImage,
            activeTeacherList
        }
    },
})
</script>

<style lang="scss" scoped>


::v-deep(.multiselect-custom) {
    .p-multiselect-label:not(.p-placeholder) {
        padding-top: .25rem;
        padding-bottom: .25rem;
    }

    .country-item-value {
        padding: .25rem .5rem;
        border-radius: 3px;
        display: inline-flex;
        margin-right: .5rem;
        background-color: var(--primary-color);
        color: var(--primary-color-text);

        img.flag {
            width: 17px;
        }
    }
}
</style>