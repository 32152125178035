<template>
    <div>
    <div class="subheader py-2 py-lg-6 subheader-solid" id="kt_subheader">
        <div class="container-fluid d-flex align-items-center justify-content-between flex-wrap flex-sm-nowrap">
            <div class="d-flex align-items-center flex-wrap mr-1">
                <div class="d-flex align-items-baseline flex-wrap mr-5">
                    <ul class="pl-0 breadcrumb breadcrumb-transparent breadcrumb-dot font-weight-bold my-2 font-size-sm">
                        <li class="breadcrumb-item">
                            {{class_list.length}} Total
                        </li>
                    </ul>
                </div>
            </div>
            <div class="d-flex align-items-center">
                <div class="card-toolbar card-actions">
                    <a href="Javascript:void(0)" @click="exportClass" class="btn font-weight-bolder export-action  mr-3 text-893BF7 "><i class="fas fa-file-export icon-md text-893BF7"></i>Export CSV Class</a>
                    <a @click="addClass" v-if="user.user_type == 'school'" href="Javascript:void(0)" class="btn font-weight-bolder add-action class-add-button">
                        <i class=" fas fa-plus-circle icon-md"></i>Add Class
                    </a>
                </div>
            </div>
        </div>
    </div>
    <div class="d-flex flex-column-fluid">
        <div class="container">
            <div class="card-header  p-0 pt-0 border-0 bg-none">
                <h3 class="card-title  u-flex">
                    <span class="card-label fw-bolder fs-3 mb-1 u-flex-1 pt-2" id="tabView">
                        <SelectButton v-model="selected_view" :options="view_options" :dataKey="value">
                            <template #option="slotProps">
                                <i :class="slotProps.option.icon"></i>
                            </template>
                        </SelectButton>
                    </span>
                    <div class="p-d-flex p-jc-between me-2 searchbar-width">
                        <span class="p-input-icon-left text-blue">
                            <i class="pi pi-search" />
                            <InputText v-if="selected_view.value == 2" class="tableSearch "  v-model="filter.filter_list['global'].value" placeholder="Search..." />
                            <InputText v-else class="tableSearch" v-model="search" placeholder="Search..." />
                        </span>
                    </div>
                </h3>
            </div>
            <div class="card card-custom" v-if="selected_view.value == 2">
                <div class="card-body">
                    <ClassTableView :class_list="class_list" :loading="class_loading" :filter="filter"></ClassTableView>
                </div>
            </div>
            <div v-else>
                <div v-if="class_loading" class="text-center align-items-center mt-5 pt-5">
                    <div class="spinner-grow text-info" role="status" style="width: 3rem; height: 3rem;">
                        <span class="sr-only">Loading...</span>
                    </div>
                </div>
                <div class="row" v-if="paginated.length > 0"> 
                    <div class="col-xl-4 col-lg-6 col-md-6 col-sm-6" v-for="(item, i) in paginated" :key="i">
                        <ClassCardView :item="item" :index="i"></ClassCardView>
                    </div>
                    <div class="col-12">
                        <Paginator 
                         v-if="!class_loading"
                        template="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                         currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
                         :rows="per_page"  @page="paginate($event, filtered_list)" :totalRecords="filtered_list.length" :rowsPerPageOptions="[6, 10,20,30,50,100,1000]"> </Paginator>
                    </div>
                </div>
                <div v-else class="pt-4">
                    <no-results-error v-if="class_loading == false" :message="`No class found!`"></no-results-error>
                </div>
            </div>
        </div>
    </div>
    <ClassStudents :student_list_watcher="student_list_watcher" :selected_class="selected_class"></ClassStudents>
    <ClassAdd :teacher_list="active_teacher_list"  :add_class_watcher="add_class_watcher" :type="action_type" :selected_class="selected_class"></ClassAdd>
    <ClassSurvey  :class_survey_watcher="class_survey_watcher" :selected_class="selected_class"></ClassSurvey>
    <ClassReport  :class_report_watcher="class_report_watcher" :source="`class`" :selected_class="selected_class"></ClassReport>
    <ConfirmDialog></ConfirmDialog>
    </div>
</template>
<script>
import  ClassGetList  from '@/composables/class/ClassGetList'
import  ClassDelete  from '@/composables/class/ClassDelete'
import  usePagination  from '@/composables/common/usePagination'


import { defineComponent, ref, reactive, onMounted, inject, watch, provide, computed } from 'vue'
import ClassAdd from './ClassAdd.vue'
import {FilterMatchMode} from 'primevue/api'
import InputText from 'primevue/inputtext'
import { useConfirm } from "primevue/useconfirm"   
import ClassStudents from './ClassStudents.vue'
import ClassSurvey from './ClassSurvey.vue'
import ClassReport from './ClassReport.vue'


import ConfirmDialog from 'primevue/confirmdialog';
import  TeachersGetList  from '@/composables/teachers/TeachersGetList'
import _ from "lodash";
import SelectButton from 'primevue/selectbutton';
import VPagination from "@hennge/vue3-pagination";
import NoResultsError from '@/components/NoResultsError.vue'
import ClassCardView from './ClassCardView.vue'
import ClassTableView from './ClassTableView.vue'
import Paginator from 'primevue/paginator';
import  YearGetList  from '@/composables/year/YearGetList'

export default defineComponent({
  components: { 
                ClassCardView, ClassTableView, NoResultsError, SelectButton, ClassAdd, InputText, 
                ClassStudents, ConfirmDialog, ClassSurvey, ClassReport, Paginator
            },
    setup() {
        const confirm = useConfirm();
        const { store, toast, http, constants } = inject('App')
        const { class_loading, class_list, getClassList } = ClassGetList(http, constants, store)
        const { delete_status, deleteClass } = ClassDelete(http, constants, toast)
        const  { loading, teacher_list, getTeacherList } = TeachersGetList(http, constants, store)
        const { activePage, setActivePage, paginated, paginate } = usePagination()
        const { yearList, getList } = YearGetList(http, constants, store)

        let add_class_watcher = ref(1)
        let selected_class = ref([])
        let student_list_watcher = ref(1)
        let class_survey_watcher = ref(1)
        
        let action_type = ref('')
        const filter = reactive({
            filter_list: { 'global': {value: null, matchMode: FilterMatchMode.CONTAINS} }
        })
        const class_report_watcher = ref(1)

        const selected_view = ref({icon: 'pi pi-th-large', value: 1})

        const view_options = ref([
            {icon: 'pi pi-th-large', value: 1},
            {icon: 'pi pi-table', value: 2},
        ]);
        const page = ref(1)
        const per_page = ref(6)
        const current_page = ref({
            first: 1,
            page: activePage.value,
            pageCount: 6,
            rows: per_page.value
        })
        const search = ref('')

        const  active_teacher_list = ref([])
        
        function addClass() {
            add_class_watcher.value++
            action_type.value = 'create'
        }
        function edit(s) {
            selected_class.value = s
            action_type.value = 'edit'
            add_class_watcher.value++
        }
        function deletePrompt(q) {
            selected_class.value = q
            confirm.require({
                message: `Are you sure you want to delete ${ selected_class.value.name}?`,
                header: 'Ooops!',
                icon: 'pi pi-exclamation-triangle',
                accept: () => {
                    deleteClass(selected_class.value.id)
                },
                reject: () => {
                }
            });
            return {deletePrompt};
        }
        
        function viewStudents(s) {
            selected_class.value = s
            student_list_watcher.value++
        }
        
        function studentSurvey(s) {
            selected_class.value = s
            class_survey_watcher.value++
        }
        
        function exportClass() {
            window.open(`${process.env.VUE_APP_API_URL}${constants.CLASS_EXPORT}?token=${localStorage.getItem("token")}`)
        }
        
        function selectReport(s) {
            selected_class.value = s
            class_report_watcher.value++
        }

        function handleCommand(c) {
            if (c.action == 'report')
                selectReport(c.data)
            if (c.action == 'view-students')
                viewStudents(c.data)
            if (c.action == 'delete')
                deletePrompt(c.data)
            if (c.action == 'edit')
                edit(c.data)
            if (c.action == 'survey')
                studentSurvey(c.data)
        }

        const filtered_list = computed(() => {
            return class_list.value.filter((obj) => {
                return (
                    obj.name
                    .toLowerCase()
                    .indexOf(search.value.toLowerCase()) != -1
                );
            });
        });

        const pageCount = computed(() => {
            let s = filtered_list.value.length / per_page.value
            return s <= 0 ? 1 : Math.floor(s)
        });
        
        watch(search, (n, o) => {
            setActivePage(0)
        });
        
        watch(class_loading, (n, o) => {
            if (class_loading.value == false)
                search.value = ''
        });

        watch(filtered_list, (n, o) => {
             current_page.value = {
                    first: 0,
                    page: activePage.value,
                    rows: current_page.value.rows,
                    pageCount: filtered_list.value.length
                }
            paginate(current_page.value, filtered_list.value)
        });

        watch(delete_status, (n, o) => {
            if (delete_status.value) {
                let index = class_list.value.findIndex(obj => obj.id === selected_class.value.id)
                class_list.value.splice(index, 1)
            }
        });

        onMounted(async () => {
            await getList()

            await getTeacherList()
            active_teacher_list.value = store.getters['TeachersModule/GET_TEACHER_LIST']
            getClassList()
        })

        provide('Class', { getClassList, handleCommand, studentSurvey })

        return {
            user: computed(() => store.getters['UserModule/GET_USER']),
            class_list, add_class_watcher, selected_class, action_type, class_loading,
            addClass, edit, deletePrompt, filter,
            viewStudents,
            student_list_watcher, getClassList, studentSurvey, class_survey_watcher,
            web_url: `${process.env.VUE_APP_API_SITE}${constants.CLASS_THUMBNAIL_PATH}`,    
            active_teacher_list, selectReport, class_report_watcher, exportClass, handleCommand,
            selected_view, view_options, search, activePage,
            paginate,paginated, filtered_list, current_page, per_page, page, pageCount
        }
    },
})
</script>
