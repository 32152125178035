import { ref } from 'vue'
export default function SurveySaveAnswer(http, constant) {
    let save_answer_loading = ref(false)

    function getCheckStudentList(student_list) {
        let ids = ''
        let names = ''
        student_list.forEach((obj) => {
                if (!ids) {
                    ids += `${obj.id}`;
                    names += `${obj.fname} ${obj.lname}`;
                } else {
                    ids += `,${obj.id}`;
                    names += `,${obj.fname} ${obj.lname}`;
                }
        });
        return [ids, names];
    }
    async function saveSurveyAnswer(class_id, term, week_no, teacher_id, student_id,  student_list, choices2, choices3,  choices4, question_id_three, questionIDFour ) {
        save_answer_loading.value = true
       console.log(choices2)
        try {
            let list = getCheckStudentList(student_list)
            const f = new URLSearchParams()
            f.append('class_id', class_id)
            f.append('term', term)
            f.append('week_no', week_no)
            f.append('teacher_id', teacher_id)
            f.append('student_id', student_id)
            f.append('answer_student_ids', list[0])
            f.append('answer_student_names', list[1])
            f.append('choices_id',  choices2.id)
            f.append('choices_name',  choices2.options)
            f.append('choices_id_three',  choices3.id)
            f.append('choices_name_three',  choices3.options)
            f.append('choices_id_four',  choices4.id)
            f.append('choices_name_four',  choices4.options)
            f.append('is_default', 1)
            f.append('question_id_three', question_id_three)
            f.append('question_id_four', questionIDFour)


            const response = await http.post(constant.SURVEY_SAVE_ANSWER, f)
            save_answer_loading.value = false
        } catch (err) {
            save_answer_loading.value = false
            console.log(err.message)
        }
    }
    return {
        save_answer_loading, saveSurveyAnswer
    }
}