 <template>
    <Sidebar  v-model:visible="display" position="right" class="p-sidebar-md"  :dismissable="false" :modal="true" :showCloseIcon="false" >
    <form  ref="studentData" @submit.prevent="addNotes"  action="post"  novalidate >
            <div class="rpanel-title text-capitalize">
                <div class="flex">
                    <div class="w-100">
                        <p>Students</p>
                        <small>Add notes for {{ selectedStudent.name }}</small>
                    </div>
                    <span @click="display = false"  class="cursor-pointer fs-20"><i class="pi pi-times"></i></span>
                </div>
            </div>
            <div class="drawer-content">
                <div  class="p-10   pt-0 mt-0">
                    <StudentsNotes  :selectedStudent="selectedStudent"></StudentsNotes>
                </div>
            </div>
    </form>
    </Sidebar>
</template>
<script>
import { defineComponent, ref, watch, computed, inject } from 'vue'
import Sidebar from 'primevue/sidebar'
import  NotesAdd  from '@/composables/notes/NotesAdd'
import  NotesGetList  from '@/composables/notes/NotesGetList'
import  NotesDelete  from '@/composables/notes/NotesDelete'
import StudentsNotes from './StudentsNotes.vue'

import moment from 'moment'
import { useConfirm } from "primevue/useconfirm";

export default defineComponent({
    components: { Sidebar,  StudentsNotes },
    props: {
        studentsNoteWatcher: {
            type: Number,
            default: 1
        },
        selectedStudent: { default: [] }
    },
    setup(props) {
        const {  utils, http, constants, toast, store } = inject('App')

        const display = ref(false)
        const addNotesWatcher = ref(1)
        watch(()=>props.studentsNoteWatcher, (n) => {
            display.value = true
            addNotesWatcher.value++
         })

         return {
             display, addNotesWatcher
         }
    },
})
</script>
