import { ref } from 'vue'
export default function StudentsAdd(http, constant, toast) {
    const create_status = ref(false)
    const createResponse = ref([])
    function getClassIds(teacher_list) {
        let ids = ''
        teacher_list.forEach((obj) => {
                if (!ids) {
                    ids += `${obj.id}`;
                } else {
                    ids += `,${obj.id}`;
                }
        });
        return ids;
    }

    async function addStudent(id, view, studentData, class_ids, thumbnail_type, thumbnail_color, student_thumb, thumbnail_src, isUpdateAvatar) {
        try {
            create_status.value = true
            createResponse.value = []

            const f = new FormData(studentData)
            f.append('thumbnail', student_thumb)
            f.append('thumbnail_color', thumbnail_color)
            f.append('thumbnail_type', thumbnail_type)
            f.append('thumbnail_src', thumbnail_src)
            f.append('class_ids', getClassIds(class_ids))
            f.append('is_update_avatar', (isUpdateAvatar) ? 1 : 0)
            if (view  == 'edit')  
                f.append('id', id)
            

            const response = await http.post(view  == 'edit' ? constant.STUDENT_EDIT :  constant.STUDENT_ADD, f)
            const { status, data } = response.data

            create_status.value = false
            createResponse.value = data.student
            toast.add({severity:'success', summary: 'Success', detail:'Student was successfully created/updated.', life: 3000});

        } catch (err) {
            create_status.value = false
            console.log(err.message)
        }
    }
    return {
        createResponse,
        create_status,
        addStudent
    }
}