<template>
   <Dialog :header="`Students List | ${selected_class.name}`"
      :draggable="false"  :modal="true" v-model:visible="display" :maximizable="true"
      :breakpoints="{'960px': '75vw', '640px': '100vw'}" :style="{width: '70vw'}"><br>
        <Students :class_id="selected_class.id"></Students>
        <template #footer>
        </template>
    </Dialog>
</template>
<script>
import { defineComponent, ref, watch } from 'vue'
import Students from '../students/Students.vue'
import Dialog from 'primevue/dialog';

export default defineComponent({
    components: { Students, Dialog },
    props: {
        student_list_watcher: {
            type: Number,
            default: 1
        },
        selected_class: {
            default: []
        },
    },
    setup(props) {
        let display = ref(false)

        watch(()=>props.student_list_watcher, (n) => {
            display.value = true
        })
        return {
            display
        }
    },
})
</script>
<style scoped>
    .card-custom {
        box-shadow: none !important;
    }
</style>