<template>
  <Sidebar v-model:visible="visible" position="right" class="p-sidebar-md" :dismissable="false" :modal="true" :showCloseIcon="false">
      <div class="rpanel-title text-capitalize">
            <div class="flex">
                <div class="w-100">
                    <p>Survey</p>
                    <small>Start Survey</small>
                </div>
                <span @click="visible = false"  class="cursor-pointer fs-20"><i class="pi pi-times"></i></span>
            </div>
        </div>
        <div class="drawer-content">
	    <div class=" pt-5 pb-10 mb-10 p-10" >
        <div class="container">
           <form id="msform">
            <ul id="progressbar">
                <li :class="{'active' : step == 1}"  id="account" class="fs-14"><strong>Term and Week</strong></li>
                <li  :class="{'active' : step == 2}" id="account"  class="fs-14"><strong>Select Survey</strong></li>
                <li  :class="{'active' : step == 3}" id="account"  class="fs-14"><strong>Students</strong></li>
                <li  :class="{'active' : step == 4}" id="account"  class="fs-14"><strong>Answer Survey</strong></li>
            </ul>
            <div class="pt-5 pl-5 pr-5 mb-5 pb-5">
            <nav aria-label="breadcrumb" class="pb-3">
                <ol class="breadcrumb">
                    <li class="breadcrumb-item" v-if="step >= 1"><a href="Javascript:void(0)">{{ selected_class.name }}</a></li>
                    <li class="breadcrumb-item" v-if="step > 1"><a href="Javascript:void(0)">{{ selected_term.text }}</a></li>
                    <li class="breadcrumb-item" v-if="step > 1"><a href="Javascript:void(0)">{{ selected_week.week_no }}</a></li>
                    <li class="breadcrumb-item" v-if="step > 2"><a href="Javascript:void(0)">{{ default_survey.name }}</a></li>
                    <li class="breadcrumb-item" v-if="step > 3" aria-current="page">{{ selected_student.name }}</li>
                </ol>
            </nav>
            <fieldset v-if="step == 1">
                <div class="d-flex pb-10">
                    <div class="w-100">
                        <SelectButton v-model="selected_term" :options="terms_list" dataKey="value">
                            <template #option="slotProps">
                                {{slotProps.option.text}}
                            </template>
                        </SelectButton>
                    </div>
                    <div style="width: 200px">
                        <Button label="Add New" @click="term_add_watcher++" icon="pi pi-plus" class="p-button-md float-right " />
                    </div>
                </div>
                <div  v-for="(item, i) in week_list" :key="i"  @click="selectWeek(item)" class="box-shadow rounded-lg mt-2 cursor-pointer  mb-0 pb-4 w-100 card">
                    <div class="d-flex">
                        <svg width="44" height="44" viewBox="0 0 44 44" fill="none" xmlns="http://www.w3.org/2000/svg"><rect width="44" height="44" rx="13" fill="#9737D0"></rect><path d="M30.3333 21.2333V22C30.3323 23.797 29.7504 25.5455 28.6744 26.9848C27.5984 28.4241 26.086 29.477 24.3628 29.9866C22.6395 30.4961 20.7977 30.4349 19.112 29.8121C17.4264 29.1894 15.9872 28.0384 15.0091 26.5309C14.031 25.0233 13.5665 23.24 13.6847 21.4469C13.8029 19.6538 14.4976 17.9469 15.6652 16.5809C16.8328 15.2148 18.4106 14.2628 20.1635 13.8668C21.9163 13.4707 23.7502 13.6519 25.3916 14.3833" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path><path d="M30.3333 15.3333L22 23.6749L19.5 21.1749" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path></svg>
                        <p class="pl-5 pt-4 fs-16">{{item.week_no}}</p>
                    </div>
                </div>
               
            </fieldset>
            <fieldset  v-if="step == 2">
                <div class="card cursor-pointer card-custom mb-5 " @click="selectSurvey">
                    <div class="card-body flex pb-3">
                        <div class="w-100">
                            <h4>{{default_survey.name}}</h4>
                            <p><a href="Javascript:void(0)">{{default_survey.slug}}</a></p>
                        </div>
                    </div>
                </div>
                <Button label="Back" @click="step = 1" icon="pi pi-chevron-left" class="p-button-sm mt-5" />
            </fieldset>
            <fieldset  v-if="step == 3">
                <div  v-for="(item, i) in active_student_list" :key="i"  @click="selecteStudent(item)" class="box-shadow  rounded-lg mt-2 cursor-pointer  mb-0 pb-3 w-100 card">
                    <span class="d-flex">
                    <Avatar
                        v-if="item.thumbnail_type == 'image'
                        || item.thumbnail_type == 'upload' 
                        || item.thumbnail_type == 'library' " 
                        :image="`${item.path}${item.thumbnail}`" class="p-mr-2" size="large" shape="circle" />
                        <div  v-if="item.thumbnail_type == 'color'" class="ml-0" :style="{ 'background-color': `#${item.thumbnail_bg}`, height: '40px', width: '40px', 'border-radius': '50%'}"></div>

                    <p class="ml-5 pt-4 fw-500 text-capitalize">{{item.name}}</p>
                    <span class="flex-1">
                        <svg  v-if="item.is_surveyed > 0" width="25px" fill="#8a19ff"  xmlns="http://www.w3.org/2000/svg" class="ionicon float-right pt-3" viewBox="0 0 512 512"><title>Checkbox</title><path d="M400 48H112a64.07 64.07 0 00-64 64v288a64.07 64.07 0 0064 64h288a64.07 64.07 0 0064-64V112a64.07 64.07 0 00-64-64zm-35.75 138.29l-134.4 160a16 16 0 01-12 5.71h-.27a16 16 0 01-11.89-5.3l-57.6-64a16 16 0 1123.78-21.4l45.29 50.32 122.59-145.91a16 16 0 0124.5 20.58z"/></svg>
                    </span>
                    </span>
                </div>
                <Button label="Back" @click="step = 2" icon="pi pi-chevron-left" class="p-button-sm mt-5" />
            </fieldset>
            <fieldset  v-if="step == 4">
                <div v-for="(item, i) in default_survey.questionslist" :key="i" class="box-shadow rounded-lg mb-5 pt-10 pb-5">
                    <p v-html="(i + 1 + '. ') + prepare(item.question, selected_student.name)" class="fw-500"></p>
                    <section class="pl-5 pr-5 pt-5 pb-5" v-if="i == 0">
                        <div class="mb-2" v-for="(student, x) in active_student_list" :key="x">
                            <div class="d-flex">
                                <Checkbox  :value="student" class="pt-3" v-model="selected_students"  />
                                <Avatar 
                                  v-if="student.thumbnail_type == 'image'
                                    || student.thumbnail_type == 'upload' 
                                    || student.thumbnail_type == 'library' " 
                                :image="`${student.path}${student.thumbnail}`" class="p-mr-2 ml-4" size="large" shape="circle" />
                                <div  v-if="student.thumbnail_type == 'color'" class="ml-4" :style="{ 'background-color': `#${student.thumbnail_bg}`, height: '40px', width: '40px', 'border-radius': '50%'}"></div>

                                <p class="pl-5 pt-2 text-capitalize">{{student.name}} </p>
                            </div>
                        </div>
                    </section>
                    <section class="pl-5 pr-5 pt-5 pb-5" v-if="item.question_type == 6">
                        <div class="mb-0" v-for="(row, x) in item.choices " :key="x">
                            <div class="d-flex">
                                <RadioButton id="city1" name="choices2" :value="row" v-model="choices2" />
                                <p class="pl-5 pt-0">
                                    {{row.options}}
                                </p>
                            </div>
                        </div>
                    </section>
                    <section class="pl-5 pr-5 pt-5 pb-5" v-if="item.question_type == 1 && i == 2">
                        <div class="mb-0 pb-0" v-for="(row, x) in item.choices" :key="x">
                            <div class="d-flex">
                                <RadioButton id="city2" name="choices3" :value="row" v-model="choices3" />
                                <p class="pl-5 pt-0">
                                    {{row.options}}
                                </p>
                            </div>
                        </div>
                    </section>
                    <section class="pl-5 pr-5 pt-5 pb-5" v-if="item.question_type == 1 && i == 3">  
                        <div class="row">
                            <div class="col-12">
                                <div class="mb-0 pb-0">
                                    <div class="row">
                                        <template  v-for="(row, x) in item.choices" :key="x">
                                        <div class="col-12 col-md-4 d-flex pt-5" v-if="x == 3">
                                            <div style="padding-top: 12px;padding-right: 12px;">
                                            <RadioButton id="city23" :value="row"  name="choices4" v-model="choices4" />
                                            </div>
                                            <img src="@/assets/images/sad.png" style="width: 40px;height: 40px"> 
                                        </div>

                                        <div class="col-12 col-md-4 d-flex pt-5" v-if="x == 6">
                                            <div  style="padding-top: 12px;padding-right: 12px;">
                                            <RadioButton id="city23" :value="row"  name="choices4" v-model="choices4" />
                                            </div>
                                            <img src="@/assets/images/neutral.png" style="width: 40px;height: 40px"> 
                                        </div>

                                        <div class="col-12 col-md-4 d-flex pt-5" v-if="x == 9">
                                            <div  style="padding-top: 12px;padding-right: 12px;">
                                            <RadioButton id="city23" :value="row" name="choices4" v-model="choices4" />
                                            </div>
                                            <img src="@/assets/images/smiling-face.png" style="width: 40px;height: 40px"> 
                                        </div>
                                    </template>
                                </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
                <div class="pt5 mt-5 mb-5 pb-5">
                    <Button label="Back" :disabled="save_answer_loading" @click="step = 3" icon="pi pi-chevron-left" class="p-button-sm mt-5" />
                    <Button label="Submit Answer" :disabled="save_answer_loading" @click="save" icon="pi pi-check" class="p-button-sm p-button-success mt-5 pull-right float-right" />
                </div>

            </fieldset>
            </div>
        </form>
      </div>
    </div>
    </div>
  </Sidebar>
  <TermAdd  :selected_class="selected_class" :term_add_watcher="term_add_watcher" :location="`class`" :selected_term="selected_term"></TermAdd>

</template>
<script>
import Sidebar from 'primevue/sidebar';
import { defineComponent, ref, watch, inject, provide  } from 'vue'
import Steps from 'primevue/steps';
import SelectButton from 'primevue/selectbutton';
import Card from 'primevue/card';
import  TermsGetList  from '@/composables/terms/TermsGetList'
import  SurveyGetDefault  from '@/composables/survey/SurveyGetDefault'
import  SurveySaveAnswer  from '@/composables/survey/SurveySaveAnswer'
import Checkbox from 'primevue/checkbox';
import RadioButton from 'primevue/radiobutton';
import Button from 'primevue/button';
import TermAdd from '../terms/TermAdd'
import Avatar from 'primevue/avatar';

export default defineComponent({
   components: { Sidebar, SelectButton, RadioButton , Button, Checkbox, TermAdd, Avatar },
    props: {
        class_survey_watcher: {
            default: 1,
            type: Number
        },
        selected_class: {
            default: []
        }
    },
    methods: {
        prepare(question, name) {
            question = question.replace('{{ Student Name }}', `<b>${name}</b>`);
            return question;
        }
    },
    setup(props) {
        const { toast, http, constants } = inject('App') 

        const { term_loading,  term_list, getTermList } = TermsGetList(http, constants)
        const { sloading,  default_survey, student_list,  getDefaultSurvey } = SurveyGetDefault(http, constants)
        const { save_answer_loading, saveSurveyAnswer } = SurveySaveAnswer(http, constants)


        const visible = ref(false)
        const terms_list = ref([
            {text: 'Term 1', value: 'Term 1'},
            {text: 'Term 2', value: 'Term 2'},
            {text: 'Term 3', value: 'Term 3'},
            {text: 'Term 4', value: 'Term 4'}
        ]);

        const week_list = ref([])
        const selected_term = ref({
            text: 'Term 1', value: 'Term 1'
        })
        const selected_segment_term = ref('Term 1')
        const selected_week = ref('Week 1')
        const step = ref(1)
        const checkeds = ref(0);
        const limit = ref(3);
        const active_student_list = ref([])
        const choices2 = ref('')
        const choices3 = ref('')
        const choices4 = ref('')
        const selected_students = ref([])
        const term_add_watcher = ref(1)
        const selected_student = ref([])
        
        function showAlert(m) {
          toast.add({severity:'error', summary: 'Ooops!', detail:m, life: 3000});
        }

        function check() {
            if (selected_students.value.length  == 4) {
                selected_students.value.splice(3, 1)
                showAlert('Please pick three students only!')
            }
        }

        function selectTerm(term) {
            let arr = []
            week_list.value = []

            for (let i = 0; i < term_list.value.length; i++) {
                let element = term_list.value[i]
                if (element.term == term) {
                    arr.push(element)
                }
            }
            week_list.value = arr
        }
        function selectWeek(item) {
            step.value = 2
            selected_week.value = item
            getDefaultSurvey(0, props.selected_class.id, selected_term.value.text, selected_week.value.week_no)
        }
        function selectSurvey() {
            step.value = 3
        }
        function save() {
            let err = 0
            if (selected_students.value.length < 3) {
                showAlert('You must pick 3 students for question number 1!')
                err = 1
            }
            if (err == 0) {
                if (choices2.value.length == 0) {
                    showAlert('Please pick answer for question number 2!')
                    err = 1
                }
            }
             if (err == 0) {
                if (choices3.value.length == 0) {
                    showAlert('Please pick answer for question number 3!')
                    err = 1
                }
            }
             if (err == 0) {
                if (choices4.value.length == 0) {
                    showAlert('Please pick answer for question number 4!')
                    err = 1
                }
            }
            if (err == 0) {
                saveSurveyAnswer(props.selected_class.id, selected_term.value.text, selected_week.value.week_no,
                selected_student.value.user_id, selected_student.value.id,
                selected_students.value, choices2.value, choices3.value, choices4.value, default_survey.value.questionslist[2].id, default_survey.value.questionslist[3].id)
            }
        } 
        function selecteStudent(item) {
            if(item.is_surveyed == 1) {
                toast.add({severity:'error', summary: 'Ooops!', detail: `${item.name.toUpperCase() } was already surveyed!`, life: 3000});
            } else {
                selected_student.value = item
                step.value = 4
                getDefaultSurvey(selected_student.value.id, props.selected_class.id, selected_term.value.text, selected_week.value.week_no)
            }
        }
        
        watch(selected_term, (n, o) => {
            selectTerm(selected_term.value.text)
        });
        watch(term_loading, (n, o) => {
            if (term_loading.value == false) {
                    selectTerm(selected_term.value.text)
            }
        });
        watch(save_answer_loading, (n, o) => {
            if (save_answer_loading.value == false) {
                step.value = 3
                selected_students.value = []
                choices3.value = ''
                choices2.value = ''
                choices4.value = ''
                getDefaultSurvey(0, props.selected_class.id, selected_term.value.text, selected_week.value.week_no)
                toast.add({severity:'success', summary: 'Thanks', detail:'Your answer to the survey was succesfully submitted.', life: 3000});
            }
        });

        
        watch(sloading, (n, o) => {
            if (sloading.value == false) {
                active_student_list.value = student_list.value
            }
        });
        watch(selected_students, (n, o) => {
           check()
        });
        watch(()=>props.class_survey_watcher, (n) => {
            visible.value = true
            getTermList(props.selected_class.id)
            step.value = 1
            selected_students.value = []
            choices3.value = ''
            choices2.value = ''
            choices4.value = ''
        })

        provide('StudentsSurvey', { getTermList })
        return {
            visible, terms_list, selected_term, week_list, selectTerm,
            step, selectWeek, default_survey, selectSurvey, student_list,
            check, active_student_list, save, choices2, checkeds, selected_students, save_answer_loading,
            term_add_watcher, choices3,
            selecteStudent, selected_student, selected_week, choices4, 
            web_url: `${process.env.VUE_APP_API_SITE}${constants.STUDENTS_THUMBNAIL_PATH}`,
        }
    },
})
</script>
<style scoped>

#grad1 {
    background-color: #9C27B0;
    background-image: linear-gradient(120deg, #FF4081, #81D4FA)
}
#progressbar {
    text-align: center;
    padding: 0px 10px 0px 10px !important;
}
#msform {
    position: relative;
    margin-top: 20px
}





select.list-dt {
    border: none;
    outline: 0;
    border-bottom: 1px solid #ccc;
    padding: 2px 5px 3px 5px;
    margin: 2px
}

select.list-dt:focus {
    border-bottom: 2px solid #341cbd
}

.card {
    z-index: 0;
    border: none;
    border-radius: 0.5rem;
    position: relative
}

.fs-title {
    font-size: 25px;
    color: #2C3E50;
    margin-bottom: 10px;
    font-weight: bold;
    text-align: left
}

#progressbar {
    margin-bottom: 30px;
    overflow: hidden;
    color: lightgrey
}

#progressbar .active {
    color: #000000
}

#progressbar li {
    list-style-type: none;
    font-size: 12px;
    width: 25%;
    float: left;
    position: relative
}

#progressbar #account:before {
    font-family: FontAwesome;
    content: "\f023"
}

#progressbar #personal:before {
    font-family: FontAwesome;
    content: "\f007"
}

#progressbar #payment:before {
    font-family: FontAwesome;
    content: "\f09d"
}

#progressbar #confirm:before {
    font-family: FontAwesome;
    content: "\f00c"
}

#progressbar li:before {
    width: 50px;
    height: 50px;
    line-height: 45px;
    display: block;
    font-size: 18px;
    color: #ffffff;
    background: lightgray;
    border-radius: 50%;
    margin: 0 auto 10px auto;
    padding: 2px
}

#progressbar li:after {
    content: '';
    width: 100%;
    height: 2px;
    background: lightgray;
    position: absolute;
    left: 0;
    top: 25px;
    z-index: -1
}

#progressbar li.active:before,
#progressbar li.active:after {
    background: #341cbd
}

.radio-group {
    position: relative;
    margin-bottom: 25px
}

.radio {
    display: inline-block;
    width: 204;
    height: 104;
    border-radius: 0;
    background: lightblue;
    box-shadow: 0 2px 2px 2px rgba(0, 0, 0, 0.2);
    box-sizing: border-box;
    cursor: pointer;
    margin: 8px 2px
}

.radio:hover {
    box-shadow: 2px 2px 2px 2px rgba(0, 0, 0, 0.3)
}

.radio.selected {
    box-shadow: 1px 1px 2px 2px rgba(0, 0, 0, 0.1)
}

.fit-image {
    width: 100%;
    object-fit: cover
}
</style>