<template>
    <div>
        <Dialog :header="`New Term & Week`" :draggable="false" :modal="true" v-model:visible="display" :maximizable="true" :breakpoints="{ '960px': '75vw', '640px': '100vw' }" :style="{ width: '40vw' }"
            ><br />

            <div class="form-group">
                <label>Select Term <span class="text-danger">*</span></label>
                <select class="form-control" name="class" v-model="active_term">
                    <option value="">Select</option>
                    <option v-for="(item, i) in terms_list" :key="i" :value="item.text">{{ item.text }}</option>
                </select>
            </div>
            <div class="form-group">
                <label>Select Week <span class="text-danger">*</span></label>
                <select class="form-control" name="class" v-model="selected_week">
                    <option value="">Select</option>
                    <option v-for="(item, i) in 13" :key="i" :value="`Week ${item}`">{{ `Week ${item}` }}</option>
                </select>
            </div>
            <template #footer>
                <Button label="Save" @click="save" icon="pi pi-check" class="p-button-md" />
            </template>
        </Dialog>
    </div>
</template>
<script>
import { defineComponent, ref, watch, inject } from "vue";
import Dialog from "primevue/dialog";
import Button from "primevue/button";

import TermsAdd from "@/composables/terms/TermsAdd";
export default defineComponent({
    components: { Dialog, Button },
    props: {
        term_add_watcher: {
            type: Number,
            default: 1,
        },
        selected_student: {
            default: [],
        },
        selected_class: {
            default: [],
        },
        selected_term: {
            default: [],
        },
        location: {
            default: "student",
            type: String,
        },
    },
    setup(props) {
        const { getTermList } = inject("StudentsSurvey");
        const { toast, http, constants } = inject("App");

        const { term_add_loading, addTerm } = TermsAdd(http, constants, toast);
        const display = ref(false);
        const terms_list = ref([
            { text: "Term 1", value: "Term 1" },
            { text: "Term 2", value: "Term 2" },
            { text: "Term 3", value: "Term 3" },
            { text: "Term 4", value: "Term 4" },
        ]);
        const selected_week = ref("Week 1");

        const week_list = ref([]);
        const active_term = ref(props.selected_term);

        function save() {
            addTerm(props.selected_class.id, active_term.value, selected_week.value);
        }
        watch(term_add_loading, (n, o) => {
            if (term_add_loading.value == false) {
                getTermList(props.selected_class.id);
                display.value = false;
            }
        });

        watch(
            () => props.term_add_watcher,
            (n) => {
                display.value = true;
                active_term.value = props.selected_term;
            }
        );
        return {
            display,
            terms_list,
            active_term,
            selected_week,
            save,
        };
    },
});
</script>
