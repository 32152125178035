import { ref } from 'vue'
export default function ClassCreate(http, constant, toast) {
    const create_status = ref(false)
    function getTeacherIDs(teacher_list) {
        let ids = ''
        if (teacher_list.length > 0) {
            teacher_list.forEach((obj) => {
                    if (!ids) {
                        ids += `${obj.id}`;
                    } else {
                        ids += `,${obj.id}`;
                    }
            });
        }
        return ids;
    }

    async function createClass(id, view, name, teacher_id, thumbnail_type, thumbnail_color, class_thumb) {
        try {
            create_status.value = true

            const f = new URLSearchParams()
            f.append('name', name)
            f.append('teacher_id', getTeacherIDs(teacher_id))
            f.append('thumbnail', class_thumb)
            f.append('thumbnail_color', thumbnail_color)
            f.append('thumbnail_type', thumbnail_type)


            if (view  == 'edit')  
                f.append('id', id)

            const response = await http.post(view  == 'edit' ? constant.CLASS_EDIT :  constant.CLASS_CREATE, f)
            create_status.value = false
            toast.add({severity:'success', summary: 'Success', detail:'Class was successfully created/updated.', life: 3000});

        } catch (err) {
            create_status.value = false
            console.log(err.message)
        }
    }
    return {
        create_status,
        createClass
    }
}