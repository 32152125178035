<template>
<div>
<div v-if="class_id == 0 && teacher_id == 0" class="subheader py-2 py-lg-6 subheader-solid" id="kt_subheader">
    <div class="container-fluid d-flex align-items-center justify-content-between flex-wrap flex-sm-nowrap">
        <div class="d-flex align-items-center flex-wrap mr-1">
            <div class="d-flex align-items-baseline flex-wrap mr-5">
                <ul class="breadcrumb breadcrumb-transparent pl-0 breadcrumb-dot font-weight-bold my-2 font-size-sm">
                    <li class="breadcrumb-item">
                        {{ student_list.length }} Total
                    </li>
                </ul>
            </div>
        </div>
        <div class="d-flex align-items-center">
            <div class="card-toolbar card-actions">
                <a href="Javascript:void(0)" @click="exportStudents" class="btn font-weight-bolder mr-3 export-action text-893BF7">
                    <i class="fas fa-file-export icon-md text-893BF7"></i>Export
                </a>
                 <a href="Javascript:void(0)" @click="importStudents" class="btn font-weight-bolder mr-3 export-action text-893BF7">
                    <i class="fas fa-file-import icon-md text-893BF7"></i>Import
                </a>
                <a href="Javascript:void(0)"  @click="addStudent" class="btn font-weight-bolder add-action  add-button-margin">
                    <i class="fas fa-user-plus icon-md "></i>Add Student
                </a>
            </div>
        </div>
    </div>
</div>

<div class="d-flex flex-column-fluid">
        <div class="container ">
            <div class="card-header  p-0 pt-0 border-0 bg-none">
                <h3 class="card-title  u-flex">

                    <span class="card-label fw-bolder fs-3 mb-1 u-flex-1 pt-2" id="tabView">
                        <SelectButton v-model="selected_view" :options="view_options" >
                            <template #option="slotProps">
                                <i :class="slotProps.option.icon"></i>
                            </template>
                        </SelectButton>
                    </span>
                    <div class="p-d-flex p-jc-between me-2 searchbar-width">
                    <Dropdown v-model="selectedClass" :options="class_list" optionLabel="name" optionValue="id" placeholder="Select a class" />

                        <span class="p-input-icon-left ml-3 text-blue">
                            <i class="pi pi-search" />
                            <InputText v-if="selected_view.value == 2" class="tableSearch "  v-model="filter.filter_list['global'].value" placeholder="Search..." />
                            <InputText v-else class="tableSearch" v-model="search" placeholder="Search..." />
                        </span>
                        <div v-if="class_id != 0 && teacher_id != 0">
                            <a href="Javascript:void(0)" v-if="user.user_type == 'teacher'" @click="addStudent" class="float-right btn font-weight-bolder add-action ">
                                <i class="fas fa-plus-circle icon-md"></i>Add Student
                            </a>
                        </div>
                    </div>
                </h3>
            </div>
            <div class="card card-custom" v-if="selected_view.value == 2">
                <div class="card-body" id="kt_content">
                    <StudentsTableView :student_list="student_list" :loading="student_loading" :filter="filter"></StudentsTableView>
                </div>
            </div>
            <div v-else>
                <div v-if="student_loading" class="text-center align-items-center mt-5 pt-5">
                    <div class="spinner-grow text-info" role="status" style="width: 3rem; height: 3rem;">
                        <span class="sr-only">Loading...</span>
                    </div>
                </div>
                <div class="row" v-if="paginated.length > 0">
                    <div class="col-xl-4 col-lg-6 col-md-6 col-sm-6" v-for="(item, i) in paginated" :key="i">
                        <StudentsCardView :item="item" :index="i"></StudentsCardView>
                    </div>
                    <div class="col-12">
                        <Paginator 
                        v-if="!student_loading"
                        template="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                         currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
                         :rows="per_page"  @page="paginate($event, filtered_list)" :totalRecords="filtered_list.length" :rowsPerPageOptions="[6, 10,20,30,50,100,1000]"> </Paginator>
                    </div>

                </div>
                <div v-else class="pt-4">
                    <no-results-error  v-if="student_loading == false" :message="`No student found!`"></no-results-error>
                </div>
            </div>
        </div>
    </div>
    <StudentsAdd :add_student_watcher="add_student_watcher" :teacher_id="teacher_id" :class_id="selectedClass" :type="action_type" :selected_student="selected_student" :class_list="class_list"></StudentsAdd>
    <StudentsSurvey :student_survey_watcher="student_survey_watcher" :selected_student="selected_student"></StudentsSurvey>
    <ConfirmDialog  v-if="class_id == 0 && teacher_id == 0"></ConfirmDialog>
    <StudentsInfo :student_info_watcher="student_info_watcher" :selected_student="selected_student"></StudentsInfo>
    <StudentsImport :student_import_watcher="student_import_watcher" :class_list="class_list" :teacher_id="teacher_id" :class_id="class_id"></StudentsImport>
    <StudentsNotesDialog :studentsNoteWatcher="studentsNoteWatcher"  :selectedStudent="selected_student"></StudentsNotesDialog>
</div>
</template>
<script>

import  ClassGetList  from '@/composables/class/ClassGetList'
import  StudentsGetList  from '@/composables/students/StudentsGetList'
import  StudentsDelete  from '@/composables/students/StudentsDelete'
import  usePagination  from '@/composables/common/usePagination'



import { defineComponent, ref, inject, onMounted, reactive, watch, computed, provide } from 'vue'
import StudentsAdd from './StudentsAdd.vue'
import { useConfirm } from "primevue/useconfirm"    
import {FilterMatchMode} from 'primevue/api'
import InputText from 'primevue/inputtext'
import ConfirmDialog from 'primevue/confirmdialog'
import StudentsSurvey from './StudentsSurvey.vue'
import StudentsNotesDialog from './StudentsNotesDialog.vue'

import StudentsInfo from './StudentsInfo'
import { ElDropdown, ElDropdownItem, ElDropdownMenu }   from  'element-plus'
import SelectButton from 'primevue/selectbutton';
import VPagination from "@hennge/vue3-pagination";
import moment from 'moment'
import NoResultsError from '@/components/NoResultsError.vue'
import StudentsCardView from './StudentsCardView.vue'
import StudentsTableView from './StudentsTableView.vue'
import StudentsImport from './StudentsImport.vue'
import Paginator from 'primevue/paginator';
import Dropdown from 'primevue/dropdown';

export default defineComponent({
    components: { Dropdown, StudentsImport, Paginator, StudentsTableView, StudentsCardView, NoResultsError, SelectButton, StudentsAdd, StudentsInfo, InputText, ConfirmDialog, StudentsSurvey, StudentsNotesDialog },
    props: {
        class_id: {
            type: Number,
            default: 0
        },
        teacher_id: {
            type: Number,
            default: 0
        }
    },
    setup(props) {
        const confirm = useConfirm()
        const { http, store, constants } = inject('App')
        const { class_loading, class_list, getClassList } = ClassGetList(http, constants, store)
        const { student_loading, student_list, getStudentList } = StudentsGetList(http, constants, store)
        const { delete_status, deleteStudent } = StudentsDelete(http, constants, store)
        const { activePage,  setActivePage, paginated, paginate } = usePagination()

        const action_type = ref('add')
        const add_student_watcher = ref(1)
        const selected_student = ref([])
        const class_id = ref(0)
        const student_survey_watcher = ref(1)
        const student_info_watcher = ref(1)
        const student_import_watcher = ref(1)
        const selectedClass = ref(props.class_id)
        const studentsNoteWatcher = ref(1)

        const filter = reactive({
            filter_list: { 'global': {value: null, matchMode: FilterMatchMode.CONTAINS} }
        })
        const selected_view = ref({icon: 'pi pi-th-large', value: 1})

        const view_options = ref([
            {icon: 'pi pi-th-large', value: 1},
            {icon: 'pi pi-table', value: 2},
        ]);
        const page = ref(1)
        const per_page = ref(6)
        const current_page = ref({
            first: 1,
            page: activePage.value,
            pageCount: 6,
            rows: per_page.value
        })

        const search = ref('')

        function addStudent() {
             action_type.value = 'add'
             add_student_watcher.value++
        }

        function edit(s) {
            selected_student.value = s.data
            action_type.value = 'edit'
            add_student_watcher.value++
        }

        function deletePrompt(q) {
            selected_student.value = q.data
            confirm.require({
                message: `Are you sure you want to delete ${ selected_student.value.fname}.  He/she will be removed from all classes.`,
                header: 'Ooops!',
                icon: 'pi pi-exclamation-triangle',
                acceptLabel: 'Continue Delete',
                accept: () => {
                    deleteStudent(selected_student.value.id)
                },
                reject: () => {
                }
            })
            return {deletePrompt}
        }
        function studentSurvey(s) {
            selected_student.value = s
            student_survey_watcher.value++
        }

        function info(s) {
            selected_student.value = s
            student_info_watcher.value++
        }

        function exportStudents() {
            window.open(`${process.env.VUE_APP_API_URL}${constants.STUDENT_EXPORT}?token=${localStorage.getItem("token")}`)
        }

        function importStudents() {
            student_import_watcher.value++
        }

        function updateList(student) {
            const newArr = student_list.value.map(obj => {
            if (obj.id === student.id) {
                return {...obj,
                    age: student.age,
                    class_id: student.class_id,
                    classes: student.classes,
                    created_at: student.created_at,
                    dob: student.dob,
                    email: student.email,
                    fname: student.fname,
                    gender: student.gender,
                    id: student.id,
                    ischecked: student.ischecked,
                    last_action: student.last_action,
                    lname: student.lname,
                    name:  student.name,
                    path: student.path,
                    school_id: student.shool_id,
                    status: student.status,
                    teacher_id: student.teacher_id,
                    thumbnail: student.thumbnail,
                    thumbnail_bg: student.thumbnail_bg,
                    thumbnail_src: student.thumbnail_src,
                    thumbnail_type: student.thumbnail_type,
                    user_id: student.user_id
                };
            }

            return obj;
            });
            student_list.value = newArr
        }

        function handleCommand(c) {
            if (c.action == 'delete')
                    deletePrompt(c)
            else if (c.action == 'edit')
                edit(c)
            else if (c.action == 'info')
                info(c.data)
            else if (c.action == 'survey')
                studentSurvey(c.data)
            else if (c.action == 'notes')
             notesDrawer(c.data)
        }
        
        function notesDrawer(c) {
            selected_student.value = c
            studentsNoteWatcher.value++
        }

        const filtered_list = computed(() => {
            return student_list.value.filter((obj) => {
                return (
                    obj.name
                    .toLowerCase()
                    .indexOf(search.value.toLowerCase()) != -1 ||
                    obj.gender.toLowerCase().indexOf(search.value.toLowerCase()) != -1
                );
            });
        });

        const pageCount = computed(() => {
            let s = filtered_list.value.length / per_page.value
            return s <= 0 ? 1 : Math.floor(s)
        });

        watch(delete_status, (n, o) => {
            if (delete_status.value) {
                let index = student_list.value.findIndex(obj => obj.id === selected_student.value.id)
                student_list.value.splice(index, 1)
            }
        })

        watch(search, (n, o) => {
            setActivePage(0)
        });

        watch(selectedClass, (n, o) => {
             setActivePage(0)
            getStudentList(selectedClass.value, props.teacher_id)
        })

        watch(student_loading, (n, o) => {
            if (student_loading.value == false)
                search.value = ''
        });

        watch(filtered_list, (n, o) => {
             current_page.value = {
                    first: 0,
                    page: activePage.value,
                    rows: current_page.value.rows,
                    pageCount: filtered_list.value.length
                }
            paginate(current_page.value, filtered_list.value)
        });
    
        onMounted(() => {
            getClassList()
            getStudentList(selectedClass.value, props.teacher_id)
        })

        provide('Student', { notesDrawer, getStudentList, updateList, handleCommand, info, studentSurvey })
        
        return {
            user: computed(() => store.getters['UserModule/GET_USER']),
            action_type, add_student_watcher, selected_student, student_list, student_loading,
            class_list, filter, studentsNoteWatcher,
            addStudent, edit, deletePrompt, getStudentList,
            web_url: `${process.env.VUE_APP_API_SITE}${constants.STUDENTS_THUMBNAIL_PATH}`,
            student_survey_watcher, studentSurvey, student_info_watcher,
            info, exportStudents, handleCommand, selected_view, view_options, search,
            paginate,paginated, filtered_list, current_page, per_page, page,
            moment, class_loading, pageCount, importStudents, student_import_watcher, selectedClass
        }
        
    },
})
</script>