<template>
   <DataTable  class="p-datatable-sm" ref="dt"  :value="class_list"
        dataKey="id" :filters="filter.filter_list"  filterDisplay="menu"  :loading="loading" responsiveLayout="stack" breakpoint="960px"
        :globalFilterFields="['name']"
        :paginator="true" :rows="6"
        :resizableColumns="true" columnResizeMode="fit"
         :rowsPerPageOptions="[6, 10,20,50, 100, 1000]"
        paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
        currentPageReportTemplate="Showing {first} to {last} of {totalRecords}">
            <template #header>
            </template>
            <Column field="name" header="Class" :sortable="true">
                    <template #body="slotProps">
                        <span class="d-flex">
                        <Avatar v-if="slotProps.data.thumbnail_type == 'image'
                                    || slotProps.data.thumbnail_type == 'upload' 
                                    || slotProps.data.thumbnail_type == 'library' "  :image="`${web_url}${slotProps.data.thumbnail}`" class="p-mr-2" size="xlarge" shape="circle" />
                        <div  v-if="slotProps.data.thumbnail_type == 'color'" :style="{ 'background-color': `#${slotProps.data.thumbnail_bg}`, height: '40px', width: '40px', 'border-radius': '50%'}"></div>

                        <p class="ml-5 pt-4">{{slotProps.data.name}}</p>
                        </span>
                    </template>
            </Column>
            <Column field="teacher" header="Teacher" :sortable="true" v-if="user.user_type == 'school'">
                <template #body="slotProps">
                    <span v-if="slotProps.data.teachers.length > 0">
                        <div style="width: 300px">
                        <span  v-for="(teacher, t) in slotProps.data.teachers" :key="t"  class="label label-lg font-weight-bold  label-light-primary label-inline mr-1 ml-1">{{ teacher.name }}</span>
                        </div>
                    </span>
                </template>
            </Column>
            <Column field="studentcount" header="# of Students" :sortable="true">
                <template #body="props">
                    <a href="Javascript:void(0)" @click="handleCommand({ action: 'view-students', data: props.data })" class="text-primary text-hover-primary">{{ props.data.studentcount}}</a>
                </template>
            </Column>
            <Column field="created_at" header="Created At" :sortable="true">
                <template #body="props">
                     <p class="text-muted pt-2" >{{ moment(props.data.created_at).format('LL') }}</p>
                </template>
            </Column>
            <Column field="id" header="">
                <template #body="slotProps">
                    <a href="javascript:void(0);" @click="studentSurvey(slotProps.data)"   v-tooltip.top="'Start Survey'" class=" mr-2" data-toggle="dropdown">
                        <span class="svg-icon svg-icon-primary svg-icon-md">
                            <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1" class="svg-icon">                                        
                                <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd"><rect x="0" y="0" width="24" height="24"></rect>
                                <path d="M7,3 L17,3 C19.209139,3 21,4.790861 21,7 C21,9.209139 19.209139,11 17,11 L7,11 C4.790861,11 3,9.209139 3,7 C3,4.790861 4.790861,3 7,3 Z M7,9 C8.1045695,9 9,8.1045695 9,7 C9,5.8954305 8.1045695,5 7,5 C5.8954305,5 5,5.8954305 5,7 C5,8.1045695 5.8954305,9 7,9 Z" fill="#000000"></path>
                                <path d="M7,13 L17,13 C19.209139,13 21,14.790861 21,17 C21,19.209139 19.209139,21 17,21 L7,21 C4.790861,21 3,19.209139 3,17 C3,14.790861 4.790861,13 7,13 Z M17,19 C18.1045695,19 19,18.1045695 19,17 C19,15.8954305 18.1045695,15 17,15 C15.8954305,15 15,15.8954305 15,17 C15,18.1045695 15.8954305,19 17,19 Z" fill="#000000" opacity="0.3"></path>
                                </g>
                            </svg>
                        </span>
                    </a>
                    <el-dropdown @command="handleCommand">
                        <span class="el-dropdown-link">
                            <a href="javascript:void(0);" class="mr-2" data-toggle="dropdown">
                                <span class="svg-icon  svg-icon-primary svg-icon-md">
                                    <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                        <g stroke="none" stroke-width="1">
                                            <rect x="14" y="9" width="6" height="6" rx="3" fill="black"/>
                                            <rect x="3" y="9" width="6" height="6" rx="3" fill="black" fill-opacity="0.7"/>
                                        </g>
                                    </svg>
                                </span>
                            </a>
                        </span>
                        <template #dropdown>
                            <el-dropdown-menu>
                                <el-dropdown-item v-if="user.user_type == 'school'" :command="{ action: 'edit', data: slotProps.data }" ><i class="pi pi-pencil text-primary"></i> Edit Class </el-dropdown-item>
                                <el-dropdown-item :command="{ action: 'view-students', data: slotProps.data }"><i class="pi pi-users  text-primary"></i> View Students</el-dropdown-item>
                                <el-dropdown-item :command="{ action: 'report', data: slotProps.data }"><i class="pi pi-chart-bar  text-primary"></i> View Reports</el-dropdown-item>
                                <el-dropdown-item v-if="user.user_type == 'school'" :command="{ action: 'delete', data: slotProps.data }"><i class="pi pi-trash  text-primary"></i>  Delete Class</el-dropdown-item>
                                <el-divider v-if="user.user_type == 'teacher'"></el-divider>
                                <el-dropdown-item :command="{ action: 'survey', data: item }">
                                    <span class="svg-icon svg-icon-primary svg-icon-md mr-2">
                                        <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1" class="svg-icon">                                        
                                            <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd"><rect x="0" y="0" width="24" height="24"></rect>
                                            <path d="M7,3 L17,3 C19.209139,3 21,4.790861 21,7 C21,9.209139 19.209139,11 17,11 L7,11 C4.790861,11 3,9.209139 3,7 C3,4.790861 4.790861,3 7,3 Z M7,9 C8.1045695,9 9,8.1045695 9,7 C9,5.8954305 8.1045695,5 7,5 C5.8954305,5 5,5.8954305 5,7 C5,8.1045695 5.8954305,9 7,9 Z" fill="#000000"></path>
                                            <path d="M7,13 L17,13 C19.209139,13 21,14.790861 21,17 C21,19.209139 19.209139,21 17,21 L7,21 C4.790861,21 3,19.209139 3,17 C3,14.790861 4.790861,13 7,13 Z M17,19 C18.1045695,19 19,18.1045695 19,17 C19,15.8954305 18.1045695,15 17,15 C15.8954305,15 15,15.8954305 15,17 C15,18.1045695 15.8954305,19 17,19 Z" fill="#000000" opacity="0.3"></path>
                                            </g>
                                        </svg>
                                    </span> Start Survey
                                </el-dropdown-item>
                            </el-dropdown-menu>
                        </template>
                    </el-dropdown>
                </template>
            </Column>
            <template #loading>
                Loading class data. Please wait.
            </template>
            <template #empty>
                No class found.
            </template>
    </DataTable>
</template>
<script>
import { defineComponent, inject, computed } from 'vue'
import { ElDropdown, ElDropdownItem, ElDropdownMenu, ElDivider }   from  'element-plus'
import DataTable from 'primevue/datatable'
import Column from 'primevue/column'
import moment from 'moment'
import Avatar from 'primevue/avatar'

export default defineComponent({
    components: { Avatar, ElDropdown, ElDropdownItem, ElDropdownMenu, DataTable, Column, ElDivider },
    props: {
        class_list: {
            default: []
        },
        loading: {
            default: false,
            type: Boolean
        },
        filter: {
            default: []
        }
    },
    setup() {
        const { store, constants } = inject('App')
        const { handleCommand, studentSurvey } = inject('Class')
        return {
            handleCommand, studentSurvey, moment,
            user: computed(() => store.getters['UserModule/GET_USER']),
            web_url: `${process.env.VUE_APP_AWS_URL}`,
        }
        
    },
})
</script>
