import { ref } from 'vue'
export default function StudentsImport(http, constant, toast) {
    const import_status = ref(false)
    async function importStudents(class_id, teachers_id , file) {
        try {
            import_status.value = true

            const f = new URLSearchParams();
            f.append('class_id', class_id)
            f.append('teachers_id', teachers_id)
            f.append('file', file)

            const response = await http.post(constant.STUDENT_IMPORT, f)
            import_status.value = false
            toast.add({severity:'success', summary: 'Success', detail:'Student csv was successfully imported.', life: 3000});

        } catch (err) {
            import_status.value = false
            console.log(err.message)
        }
    }
    return {
        import_status,
        importStudents
    }
}