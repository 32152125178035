import { ref } from 'vue'
export default function StudentsDelete(http, constant, toast) {
    const delete_status = ref(false)
    async function deleteStudent(id) {
        try {
            delete_status.value = true
            const f = new URLSearchParams()
            f.append('id', id)
            const response = await http.post(constant.STUDENT_DELETE, f)
            toast.add({severity:'success', summary: 'Success', detail:'Student was successfully deleted.', life: 3000});

            delete_status.value = false
        } catch (err) {
            delete_status.value = false
        }
    }
    return {
        delete_status,
        deleteStudent
    }
}