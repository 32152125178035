import { ref } from 'vue'
export default function TeachersGetList(http, constant, store) {
    let loading = ref(false)
    let teacher_list = ref([])

    async function getTeacherList() {
        loading.value = true;
        try {
            const response = await http.post(constant.teachersgetlist, [])
            loading.value = false
            teacher_list.value = response.data.data
            store.commit('TeachersModule/SET_TEACHER_LIST', teacher_list.value)

        } catch (err) {
            loading.value = false;
        }
    }
    return {
        loading, teacher_list, getTeacherList
    }
}