<template>
    <Sidebar  v-model:visible="display" position="right" class="p-sidebar-md"  :dismissable="false" :modal="true" :showCloseIcon="false" >
    <form  ref="studentData" @submit.prevent="add"  action="post"  novalidate >
            <div class="rpanel-title text-capitalize">
                <div class="flex">
                    <div class="w-100">
                        <p>Students</p>
                        <small v-if="type == 'add'">Add Student</small>
                        <small v-else>Edit Student</small>

                    </div>
                    <span @click="display = false"  class="cursor-pointer fs-20"><i class="pi pi-times"></i></span>
                </div>
            </div>
            <div class="drawer-content">
                <div  class="p-10   pt-0 mt-0">
                    <div class="form-group">
                        <label>Select class <span class="text-danger">*</span></label>
                        <MultiSelect v-model="selected_class" :options="filter_class_list" optionLabel="name" placeholder="Select class" :filter="true" class="multiselect-custom">
                            <template #value="slotProps">
                                <div class="country-item country-item-value" v-for="option of slotProps.value" :key="option.id">
                                    <div  class="pl-3 pr-3">{{option.name}}</div>
                                </div>
                                <template v-if="!slotProps.value || slotProps.value.length === 0">
                                    Select class
                                </template>
                            </template>
                            <template #option="slotProps">
                                <div class="country-item d-flex">
                                    <div >{{slotProps.option.name}}</div>
                                </div>
                            </template>
                        </MultiSelect>
                    </div>

                    <template v-if="selected_class != '' && selected_class != 0">
                    <div class="row">
                        <div class="col-sm-6 col-12">
                            <div class="form-group">
                                <label>First Name <span class="text-danger">*</span></label>
                                <input type="text" class="form-control" v-model="fname" name="fname" placeholder="Enter first name" required/>
                            </div>
                        </div>
                        <div class="col-sm-6 col-12">
                            <div class="form-group">
                                <label>Last Name <span class="text-danger">*</span></label>
                                <input type="text" class="form-control" v-model="lname" name="lname" placeholder="Enter last name" required/>
                            </div>
                        </div>
                    </div>
                    <div class="form-group">
                        <label>Email <span class="text-danger">*</span></label>
                        <input type="email" class="form-control" v-model="email" name="email" placeholder="Enter email" required/>
                    </div>
                    <div class="form-group">
                        <label>Gender <span class="text-danger">*</span></label>
                        <div class="mb-3" v-for="(row, x) in ['Male', 'Female', 'Non-binary']" :key="x">
                                <div class="p-field-radiobutton">
                                <RadioButton id="yesno" name="gender" :value="row" v-model="gender" />
                                <label for="yesno">{{ row }}</label>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-sm-6 col-12">
                            <div class="form-group">
                                <label>Age <span class="text-danger">*</span></label>
                                <input type="number" class="form-control" v-model="age" name="age" placeholder="Enter age" required/>
                            </div>
                        </div>
                        <div class="col-sm-6 col-12">
                            <label for="basic" class="mb-0">Birthdate (optional)</label>
                            <div class="form-group pt-1">
                                    <Calendar id="basic" v-model="dob" name="dob" class="w-100" />
                            </div>
                        </div>
                    </div>
                    <div class="form-group">    
                        <label class="w-100">Profile photo</label>
                        <div class="row mb-5 pb-5" >
                            <div class="col-sm-12 col-md-12">
                                <RadioButton id="multi" :value="1" v-model="thumbnail_src" />
                                <label for="multi" class="checkbox-label">(Automatic) Generate from random avatars</label>
                            </div>
                            <div class="col-sm-12 col-md-12 pt-4">
                                <RadioButton id="long"  :value="2" v-model="thumbnail_src" />
                                <label for="long" class="checkbox-label">Select</label>
                            </div>
                        </div>
                        <div v-if="thumbnail_src == 1 && type == 'edit'">
                            <div>
                                <p>Update avatar?</p>
                                <InputSwitch v-model="isUpdateAvatar" />
                            </div>
                        </div>
                        <div v-if="thumbnail_src == 2">
                            <el-radio-group v-model="thumbnail_type" style="margin-bottom: 30px">
                                <el-radio-button label="color">Color</el-radio-button>
                                <el-radio-button label="upload"  >Upload</el-radio-button>
                                <el-radio-button label="image"  >Free Images</el-radio-button>
                                <el-radio-button label="library"  >Image Library</el-radio-button>
                            </el-radio-group>
                            <div class="row pt-0 mt-0" v-if="thumbnail_type == 'color'">
                                <div class="col-12 pt-0 mt-0">
                                    <div class="box-shadowv4 ">
                                         <el-color-picker v-model="thumbnail_color" />
                                    </div>
                                </div>
                            </div>
                            <div class="row pt-0 mt-0" v-if="thumbnail_type == 'upload'">
                                <div class="col-12 pt-0 mt-0">
                                    <div class="thumb_preview cursor-pointer" >
                                        <img v-if="upload_thumb != ''"  :src="`${path}${upload_thumb}`" style="width: 200px; height: 198px">
                                        <i  v-if="upload_thumb == ''"   class="pi pi-cloud-upload icon-download"></i>
                                        <i @click="resetUploadThumbPreview"  v-if="upload_thumb != ''" class="pi pi-times icon-close"></i>
                                    </div>
                                    <div class="upload-example__buttons-wrapper flex">
                                        <button  type="button" class="upload-example__button  mt-3" @click="$refs.file.click()">
                                            <input ref="file" type="file" accept="image/*" @change="loadImage($event)" />Select Image
                                        </button>
                                    </div>
                                </div>
                            </div>
                            <div class="row pt-0 mt-0" v-if="thumbnail_type == 'library'">
                                <div class="col-12 pt-0 mt-0">
                                    <div class="thumb_preview cursor-pointer">
                                        <img v-if="library_thumb != ''"  @click="showLibrary" :src="`${path}${library_thumb}`" style="width: 200px; height: 198px">
                                        <i  v-if="library_thumb == ''"  @click="showLibrary" class="pi pi-images icon-download"></i>
                                        <i @click="resetLibraryThumbPreview"  v-if="library_thumb != ''" class="pi pi-times icon-close"></i>
                                    </div>
                                    <span>Recommended size is 120x120</span>
                                </div>
                            </div>
                            <div class="row pt-0 mt-0" v-if="thumbnail_type == 'image'">
                                <div class="col-12 pt-0 mt-0">
                                    <div class="thumb_preview cursor-pointer">
                                        <img v-if="student_thumb != ''"  @click="uploadEvent" :src="`${path}${student_thumb}`" style="width: 200px; height: 198px">
                                        <i  v-if="student_thumb == ''"  @click="uploadEvent" class="pi pi-cloud-upload icon-download"></i>
                                        <i @click="resetPreview"  v-if="student_thumb != ''" class="pi pi-times icon-close"></i>
                                    </div>
                                    <span>Recommended size is 120x120</span>
                                </div>
                            </div>
                        </div>

                    </div>
                    </template>
                </div>
            </div>
            <div class="r-panel-footer drawer-footer--v2">
                <div class="r-panel-footer-content">
                    <button type="button" :disabled="create_status" @click="display = false" class="cursor-pointer btn btn-sm btn-secondary om-add-contact-toggle mr-2">Cancel</button>
                    <button type="submit" :disabled="create_status" class="btn btn-sm btn-info save mr-2">
                        <span  v-if="!create_status">  Save</span>
                        <span v-else><i class="fas fa-spinner fa-spinner"></i> Saving...</span>
                    </button>
                </div>
            </div>
    </form>
</Sidebar>
<PexelsImages :modules="`StudentsModule/SET_STUDENT_THUMB`" :pexels_image_watcher="pexels_image_watcher"  :source='`student_thumb`'></PexelsImages>
<ImageLibrary :path="`thumbnail/students`" :modules="`StudentsModule/SET_STUDENT_LIBRARY_THUMB`"   :thumbnail_type="`thumbnail`" :image_library_watcher="image_library_watcher" :type="`student_library_thumb`"></ImageLibrary>
<ImageCropper :modules="`StudentsModule/SET_STUDENT_UPLOAD_THUMB`" :ratioH="120" :ratioW="120" :selected_image="upload_thumb_set.src" :image_type="`blob`" :source="`student_upload_thumb`" :image_cropper_watcher="image_cropper_watcher"></ImageCropper>


</template>
<script>

import  StudentsAdd  from '@/composables/students/StudentsAdd'

import { defineComponent, ref, watch, inject, computed, reactive, provide } from 'vue'
import Sidebar from 'primevue/sidebar';
import RadioButton from 'primevue/radiobutton';
import Calendar from 'primevue/calendar';
import PexelsImages from '@/components/PexelsImages.vue'
import { ElRadioGroup, ElRadioButton, ElColorPicker }   from  'element-plus'
import ImageCropper from '@/components/ImageCropper.vue';
import ImageLibrary from '@/components/ImageLibrary.vue';
import MultiSelect from 'primevue/multiselect';
import InputSwitch from 'primevue/inputswitch'

export default defineComponent({
       props: {
        add_student_watcher: {
            tpe: Number,
            default: 1
        },
        selected_student: {
            default: []
        },
        active_class: {
            default: []
        },
        type: {
            default: 'add',
            type: String
        },
        class_list: {
            default: []
        },
        class_id: {
            type: Number,
            default: 0
        },
        teacher_id: {
            type: Number,
            default: 0
        }
    },
    components: { InputSwitch, ElColorPicker, MultiSelect, ImageCropper, ImageLibrary, ElRadioGroup, ElRadioButton,  Sidebar, PexelsImages, RadioButton, Calendar },
    setup(props) {
        const { utils, store, toast, http, constants } = inject('App') 

        const display = ref(false)
        const fname = ref('')
        const lname = ref('')
        const email = ref('')
        const selected_class = ref([])
        const studentData = ref('')
        const gender = ref('Male')
        const age = ref()
        const dob = ref()

        const { getStudentList, updateList } = inject('Student')
        const { createResponse, create_status, addStudent } = StudentsAdd(http, constants, toast)
        const thumbnail_type = ref('color')
        const pexels_image_watcher = ref(1)
        const thumbnail_color = ref('#893BF7')
        const thumbnail_src = ref(1)
        const filter_class_list = ref([])

        let upload_thumb_set = reactive({
				src: null,
				type: null
        })
        const image_cropper_watcher = ref(1)
        const image_library_watcher = ref(1)
        const isUpdateAvatar = ref(false)

        
        let student_thumb =  computed(() => store.getters['StudentsModule/GET_STUDENT_THUMB'])
        let upload_thumb =  computed(() => store.getters['StudentsModule/GET_STUDENT_UPLOAD_THUMB'])
        let library_thumb =  computed(() => store.getters['StudentsModule/GET_STUDENT_LIBRARY_THUMB'])
        let path =  computed(() => store.getters['StudentsModule/GET_PATH'])

        function add() {
            let image  = ''
            if (thumbnail_type.value == 'upload')
                image = upload_thumb.value
            else if (thumbnail_type.value == 'library')
                image = library_thumb.value
            else
                image = student_thumb.value

            addStudent(props.selected_student.id, props.type, studentData.value, selected_class.value, thumbnail_type.value,
                        removeHex(thumbnail_color.value), image, thumbnail_src.value, isUpdateAvatar.value)
        }
        function removeHex(str) {
            return str = str.substring(1);
        }
        function uploadEvent() {
          pexels_image_watcher.value++
        }
        function resetPreview() {
            if (props.type == 'add')
              store.commit('StudentsModule/SET_STUDENT_THUMB', '')
            else 
              store.commit('StudentsModule/SET_STUDENT_THUMB', props.selected_student.thumbnail)
        }
        function resetUploadThumbPreview() {
            if (props.type == 'add')
              store.commit('StudentsModule/SET_STUDENT_UPLOAD_THUMB', '')
            else 
              store.commit('StudentsModule/SET_STUDENT_UPLOAD_THUMB', props.selected_student.thumbnail)
        }
        function resetLibraryThumbPreview() {
            if (props.type == 'add')
              store.commit('StudentsModule/SET_STUDENT_LIBRARY_THUMB', '')
            else 
              store.commit('StudentsModule/SET_STUDENT_LIBRARY_THUMB', props.selected_student.thumbnail)
        }
        function loadImage(event) {
			const { files } = event.target;
			if (files && files[0]) {
				if (upload_thumb_set.src) {
					URL.revokeObjectURL(upload_thumb_set.src)
				}
				const blob = URL.createObjectURL(files[0]);
				const reader = new FileReader();
				reader.onload = (e) => {
                    upload_thumb_set.src = blob
                    upload_thumb_set.type =  utils.getMimeType(e.target.result, files[0].type)
                    image_cropper_watcher.value++
                    
				};
				reader.readAsArrayBuffer(files[0]);
			}
		}
        function showLibrary() {
            image_library_watcher.value++
        }
        function setSelectedClass(class_list) {
            selected_class.value = []
            if (class_list.length > 0) {
                for(let i = 0; i < class_list.length; i++) {
                    selected_class.value.push({
                        id: class_list[i].class_id,
                        name: class_list[i].classname[0]
                    })
                }
            }
        }
        function setFilteredClassList() {
            filter_class_list.value = []
            if (props.class_list.length > 0) {
                for(let i = 0; i < props.class_list.length; i++) {
                    filter_class_list.value.push({
                        id: props.class_list[i].id,
                        name: props.class_list[i].name
                    })
                }
            }

        }
        function closeDialog(){}
        watch(create_status, (n, o) => {
            if (create_status.value == false) {
                if (props.type == 'add')
                    getStudentList(props.class_id, props.teacher_id)
                else
                updateList(createResponse.value)
                display.value = false
            }
        });
        watch(()=>props.add_student_watcher, (n) => {
            display.value = true
            setFilteredClassList()
            if (props.type == 'edit') {
                fname.value = props.selected_student.fname
                lname.value = props.selected_student.lname
                email.value = props.selected_student.email
                age.value = props.selected_student.age
                gender.value = props.selected_student.gender
                dob.value = props.selected_student.dob

                if (gender.value == null)
                    gender.value = 'Male'

                store.commit('StudentsModule/SET_STUDENT_THUMB', props.selected_student)
                store.commit('StudentsModule/SET_STUDENT_UPLOAD_THUMB', props.selected_student)
                store.commit('StudentsModule/SET_STUDENT_LIBRARY_THUMB', props.selected_student)


                thumbnail_type.value = props.selected_student.thumbnail_type
                thumbnail_color.value = `#${props.selected_student.thumbnail_bg}`
                thumbnail_src.value = props.selected_student.thumbnail_src

                setSelectedClass(props.selected_student.classes);

            } else {
                selected_class.value = props.class_id
                fname.value = ''
                lname.value = ''
                email.value = ''
                age.value = ''
                dob.value = ''
                gender.value = 'Male'
                store.commit('StudentsModule/SET_STUDENT_THUMB', '')
                store.commit('StudentsModule/SET_STUDENT_UPLOAD_THUMB', '')
                store.commit('StudentsModule/SET_STUDENT_LIBRARY_THUMB', '')
                store.commit('StudentsModule/SET_PATH', process.env.VUE_APP_AWS_URL)


                thumbnail_type.value = 'color'
                thumbnail_color.value = '#893BF7'
                thumbnail_src.value = 1
            }
            isUpdateAvatar.value = false

        })
        provide('CropperParent', { closeDialog })

        return {
            add,
            display, fname, lname, create_status, selected_class, email,
            web_url: `${process.env.VUE_APP_AWS_URL}`,
            web_class_url: `${process.env.VUE_APP_AWS_URL}${constants.CLASS_THUMBNAIL_PATH}`,    
            filter_class_list, isUpdateAvatar,
            studentData, gender, age, dob, pexels_image_watcher, thumbnail_type, thumbnail_color, thumbnail_src, student_thumb,
            uploadEvent, resetPreview, upload_thumb_set,upload_thumb, library_thumb,
            image_cropper_watcher, image_library_watcher, showLibrary, loadImage, resetUploadThumbPreview, resetLibraryThumbPreview,
            path
            
        }

    },
})
</script>

<style lang="scss" scoped>
::v-deep(.multiselect-custom) {
    .p-multiselect-label:not(.p-placeholder) {
        padding-top: .25rem;
        padding-bottom: .25rem;
    }

    .country-item-value {
        padding: .25rem .5rem;
        border-radius: 3px;
        display: inline-flex;
        margin-right: .5rem;
        background-color: var(--primary-color);
        color: var(--primary-color-text);

        img.flag {
            width: 17px;
        }
    }
}
</style>